import React, { useState } from 'react';
import map from 'lodash/map';
import { CustomRadio } from '../../../components/CustomRadio';
import { dataCategoryFilter } from '../../../services/mocks';

interface Props {
  onClick: any;
  typeSearch: any;
}

export const FormCategoryFilter = ({ onClick, typeSearch }: Props) => {
  const [selectedCategory, setSelectedCategory] = useState<number>();

  const onProductCategoryClick = (index: number, link: string) => {
    setSelectedCategory(index);
    if (typeof window !== 'undefined' && link) {
      return (window.location.href = link);
    }
    onClick(index);
  };

  const renderCustomRadio = () => {
    return map(dataCategoryFilter, item => {
      const { link, id, label } = item;
      const idNew = parseInt(id, 10);
      return (
        <div key={id + label} className="mr8 mb12">
          <CustomRadio
            className="boulder b--boulder"
            id={`${id}-${selectedCategory}`}
            iconName={item.iconName}
            name="radio"
            value={item.label}
            label={item.label}
            defaultChecked={idNew === typeSearch}
            checked={idNew === typeSearch}
            onClick={onProductCategoryClick}
            index={parseInt(id, 10)}
            link={link}
          />
        </div>
      );
    });
  };

  return (
    <div className="w-100 mt32" style={{ maxWidth: 345 }}>
      <h5 className="f16 fw7 ttu abbey ma0 mb16">Categoria</h5>
      <div className="flex flex-wrap mb16">{renderCustomRadio()}</div>
    </div>
  );
};
