import React from 'react';

interface Props {
  value: string;
}

export const MyAccount = ({ value }: Props) => {
  return (
    <li className="pt8 pt12-ns">
      <span className="f14 f16-ns fw6 abbey">{value}</span>
    </li>
  );
};
