import React from 'react';
import cx from 'classnames';
import style from './Components.module.css';

const ellipsis = (phrase: string, where: number) => {
  return phrase.substring(0, where) + (phrase.length > where ? '...' : '');
};

interface Props {
  value: string;
  isMyAds?: boolean;
}

export const StoreName = ({ value, isMyAds }: Props) => {
  const cls = cx('ma0 abbey lh-solid', {
    'f12 f18-l fw700 ttu': !isMyAds,
    [style.storeNameBox]: !isMyAds,
    'f20 normal lh-title fw3': isMyAds,
  });

  const liCls = cx('ph16', {
    pt8: !isMyAds,
    'pt4 pt2-ns': isMyAds,
  });

  const textToRender = isMyAds ? value : ellipsis(value, 20);

  return (
    <li className={liCls}>
      <h2 className={cls} title={value}>
        {textToRender}
      </h2>
    </li>
  );
};
