import React from 'react';
import cx from 'classnames';
import { Button } from '../../../components/Button';
import { FloatButtonSidebarCircle } from '../../../components/FloatButton';
import { Icon } from '../../../components/Icons';
import styles from '../style.module.css';

interface Props {
  sidebarOpen: boolean;
  handleFilterClick: any;
  onSetSidebarOpen?: () => void;
}
export const FilterButtons = ({ sidebarOpen, handleFilterClick, onSetSidebarOpen }: Props) => {
  if (sidebarOpen) {
    return null;
  }
  const styleWrapper = cx(
    'flex-ns flex-m flex-l items-center justify-center dn fixed z-2 ',
    {
      [styles.openSideBarDockedRightWrapper]: !sidebarOpen,
      [styles.openSideBarDockedLeftWrapper]: sidebarOpen,
    },
    styles.btnOffSideBar,
  );
  const styleCoupleButton = cx(
    'flex pointer items-center pa0 br--right',
    { [styles.openSideBarDockedButton]: sidebarOpen },
    styles.btnFilter,
  );
  const renderMobileFilter = () => {
    return (
      <div className="dn-ns">
        <FloatButtonSidebarCircle onClick={handleFilterClick} />
      </div>
    );
  };

  return (
    <>
      <div className={styleWrapper}>
        <Button
          type="button"
          classButton={styleCoupleButton}
          onClick={onSetSidebarOpen}
          resetClass
        >
          <div className="flex items-center justify-center mr4 ml4">
            <Icon width={24} height={24} name="SvgIconSearchFilter" />
          </div>
          <div className="flex items-center justify-center">
            <Icon width={16} height={16} name="SvgIconArrowDownBold" />
          </div>
        </Button>
      </div>
      {renderMobileFilter()}
    </>
  );
};
