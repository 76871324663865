import React from 'react';
import cx from 'classnames';
import { Button } from '../../Button';
import { Icon } from '../../Icons';
import styles from '../style.module.css';

interface Props {
  onClick: any;
  sidebarOpen: boolean;
}

export const FloatButtonSideBar = ({ onClick, sidebarOpen }: Props) => {
  if (!sidebarOpen) {
    return null;
  }
  const styleWrapperButtons = cx(
    'dn db-ns db-m db-l w2 h2 flex-ns flex-m flex-l items-center justify-center absolute z-2',
    styles.wrapperButtonSideBar,
  );
  const btnClass = cx('flex justify-center items-center', styles.btnFilter);
  return (
    <div className={styleWrapperButtons}>
      <Button classButton={btnClass} resetClass onClick={onClick} type="button">
        <div className="flex items-center justify-center">
          <Icon className="rotate-180" width={16} height={16} name="SvgIconArrowDownBold" />
        </div>
      </Button>
    </div>
  );
};
