import React from 'react';
import cx from 'classnames';
import { map, isEmpty } from 'lodash';
import { GridCell } from '../../components/GridCell';
import { LoaderCard } from '../../components/Loaders';
import { EmptyState } from '../AsideSearchBarAndContent/components/EmptyState';
import { ServiceCard } from './components/ServiceCard';
import styles from './style.module.css';
import { ServiceProps } from './types';

export const Services: React.FC<ServiceProps> = ({
  stores,
  isLoading,
  handleSearchAgain,
  messageEmptyState,
}) => {
  if (isLoading) {
    return <LoaderCard />;
  }

  if (isEmpty(stores)) {
    return <EmptyState searchAgain={handleSearchAgain} message={messageEmptyState} />;
  }

  const renderCards = () => {
    const hasOpenSidebar = [1];

    return map(stores, store => {
      return (
        <GridCell
          key={store.id}
          className="pt0-ns pb12 pb16-ns pb24-m pb24-l pr12 pr24-m pr24-l flex"
          width={hasOpenSidebar}
        >
          <ServiceCard {...store} />
        </GridCell>
      );
    });
  };

  return (
    <div
      className={
        // tslint:disable-next-line: jsx-no-multiline-js
        cx('relative pt24-ns pt24-m pt0-l pb0 pb8-ns pb0-m pb0-l pl24-l pr24-l', styles.gridCards)
      }
    >
      {renderCards()}
    </div>
  );
};
