import React, { FunctionComponent } from 'react';
import {
  StoreName,
  ILocation,
  Location,
  IOffer,
  Offers,
  MyAccount,
  IMyStore,
  MyStore,
} from './components';

interface Props {
  storeName: string;
  location?: ILocation;
  offers?: IOffer[];
  myAccount?: string;
  myStore?: IMyStore;
  isMyAds?: boolean;
  borderBottom?: boolean;
}

export const CardContent: FunctionComponent<Props> = ({
  myAccount,
  storeName,
  isMyAds,
  location,
  myStore,
  offers,
  borderBottom,
}) => {
  return (
    <div className="w-100 tc">
      <ul className="list ma0 pa0 tc">
        {myAccount && <MyAccount value={myAccount} />}
        {storeName && <StoreName value={storeName} isMyAds={isMyAds} />}
        {location && <Location city={location.city} state={location.state} />}
        {offers && <Offers offers={offers} />}
        {myStore && (
          // tslint:disable-next-line: jsx-no-multiline-js
          <MyStore value={myStore.value} link={myStore.link} borderBottom={borderBottom} />
        )}
      </ul>
    </div>
  );
};
