import React, { useState, useEffect } from 'react';
import { Select } from '../../../components/Select';
import { findPartCategories } from '../../../utils/findPartCategories';

interface Props {
  onClick?: (ev: any) => void;
  typeSearch?: string;
}

export const FilterSelectCategory = ({ onClick, typeSearch = '' }: Props) => {
  const [selectedCategorie, setSelectedCategorie] = useState<any>(null);
  const [selectedSubCategorie, setSelectedSubCategorie] = useState<any>(null);
  const [selectedSubSubCategorie, setSelectedSubSubCategorie] = useState<any>(null);

  const [categories, setCategories] = useState<any[]>();
  const [subCategories, setSubCategories] = useState<any[]>();
  const [subSubCategories, setSubSubCategories] = useState<any[]>();

  useEffect(() => {
    const findCategories = async () => {
      const data = await findPartCategories();
      setCategories(data.filter(item => item.parent === null));
      setSubCategories(data.filter(item => item.parent !== null && item.subParent === null));
      setSubSubCategories(data.filter(item => item.parent !== null && item.subParent !== null));
    };
    findCategories();
  }, []);

  useEffect(() => {
    const ev = handleOnClick();
    onClick(ev);
  }, [selectedCategorie, selectedSubCategorie, selectedSubSubCategorie]);

  useEffect(() => {
    if (typeSearch && typeSearch?.length > 0 && categories && subCategories && subSubCategories) {
      const [selected] = typeSearch;
      const isCategorie = categories.find(item => item.value === selected);
      const isSubCategorie = subCategories.find(item => item.value === selected);
      const isSubSubCategorie = subSubCategories.find(item => item.value === selected);

      if (isSubSubCategorie) {
        setSelectedSubSubCategorie(isSubSubCategorie);
        setSelectedCategorie(categories.find(item => item.value === isSubSubCategorie.parent));
        setSelectedSubCategorie(
          subCategories.find(item => item.value === isSubSubCategorie.subParent),
        );
        return;
      }

      if (isSubCategorie) {
        setSelectedSubCategorie(isSubCategorie);
        setSelectedCategorie(categories.find(item => item.value === isSubCategorie.parent));
        return;
      }

      if (isCategorie) {
        setSelectedCategorie(isCategorie);
        return;
      }
    }

    setSelectedCategorie(null);
    setSelectedSubCategorie(null);
    setSelectedSubSubCategorie(null);
    return;
  }, [typeSearch, categories, subCategories, subSubCategories]);

  const handleOnClick = () => {
    const filter = {
      part_categorie: { in: [] },
      categories,
      subCategories,
      subSubCategories,
    };

    if (selectedSubSubCategorie) {
      filter.part_categorie.in.push(selectedSubSubCategorie.value);
      return filter;
    }

    if (selectedSubCategorie) {
      filter.part_categorie.in.push(selectedSubCategorie.value);
      subSubCategories
        .filter(item => item.subParent === selectedSubCategorie.value)
        .forEach(item => {
          filter.part_categorie.in.push(item.value);
        });
      return filter;
    }

    if (selectedCategorie) {
      filter.part_categorie.in.push(selectedCategorie.value);
      subCategories
        .filter(item => item.parent === selectedCategorie.value)
        .forEach(item => {
          filter.part_categorie.in.push(item.value);
        });
      subSubCategories
        .filter(item => item.parent === selectedCategorie.value)
        .forEach(item => {
          filter.part_categorie.in.push(item.value);
        });
      return filter;
    }

    return {};
  };

  const handleChangeCategorie = e => {
    setSelectedCategorie(e);
    setSelectedSubCategorie(null);
    setSelectedSubSubCategorie(null);
  };

  const handleChangeSubCategorie = e => {
    setSelectedSubCategorie(e);
    setSelectedSubSubCategorie(null);
  };

  const handleChangeSubSubCategorie = e => {
    setSelectedSubSubCategorie(e);
  };

  const renderCategories = () => {
    if (categories && categories.length > 0) {
      return (
        <div className="flex flex-wrap mb16">
          <Select
            className="w-100"
            options={categories}
            onChange={handleChangeCategorie}
            title="Categoria da peça"
            selected={selectedCategorie}
            locationDropdown="selectCategorie"
          />
        </div>
      );
    }
    return <></>;
  };

  const renderSubCategories = () => {
    if (selectedCategorie) {
      const items = subCategories.filter(item => item.parent === selectedCategorie.value);
      if (items.length > 0) {
        return (
          <div className="flex flex-wrap mb16">
            <Select
              className="w-100"
              options={items}
              onChange={handleChangeSubCategorie}
              title="Sub categoria da peça"
              selected={selectedSubCategorie}
              locationDropdown="selectSubCategory"
            />
          </div>
        );
      }
    }
    return <></>;
  };

  const renderSubSubCategories = () => {
    if (selectedSubCategorie) {
      const items = subSubCategories.filter(item => item.subParent === selectedSubCategorie.value);
      if (items.length > 0) {
        return (
          <div className="flex flex-wrap mb16">
            <Select
              className="w-100"
              options={items}
              onChange={handleChangeSubSubCategorie}
              title="Tipo da peça"
              selected={selectedSubSubCategorie}
              locationDropdown="selectSubSubCategory"
            />
          </div>
        );
      }
    }
    return <></>;
  };

  return (
    <div className="w-100 mt32" style={{ maxWidth: 345 }} id="selectCategory">
      <h5 className="f16 fw7 ttu abbey ma0 mb16">Categoria da peça</h5>
      {renderCategories()}
      {renderSubCategories()}
      {renderSubSubCategories()}
    </div>
  );
};
