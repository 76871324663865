import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import _filter from 'lodash/filter';
import _map from 'lodash/filter';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import _toPairs from 'lodash/toPairs';
import { FilterWrapper } from '../../../components/FilterWrapper';
import { Select } from '../../../components/Select';
import styles from './style.module.css';

const isMobile = typeof window !== 'undefined' ? window.innerWidth < 768 : false;

interface Option {
  value: string;
  label: string;
}

interface Props {
  getValues: (type: string, e: Option) => void;
  modelYears: any;
  selectedFilter: any;
  onFocus?: () => void;
}

export const FormYearInterval = ({ getValues, modelYears, selectedFilter, onFocus }: Props) => {
  const styleSelect = cx('ml12', styles.select);

  const [yearsLimit, setYearLimit] = useState<any[]>([]);
  const [initialYear, setInitialYear] = useState();
  const [finalYear, setFinalYear] = useState();

  useEffect(() => {
    if (!selectedFilter) {
      setInitialYear(null);
      setFinalYear(null);
    }
  }, [selectedFilter]);

  const currentYear = new Date().getFullYear();

  const calcIntervalYear = () => {
    if (modelYears) {
      const modelYearsFormatted: any = [];
      _map(modelYears, item => {
        const newItem = _toPairs(item)[0];

        const newYear = parseInt(newItem[0], 10);
        modelYearsFormatted.push({
          value: newYear.toString(),
          label: newYear.toString(),
        });
      });
      if (!yearsLimit.length) {
        setYearLimit(_orderBy(modelYearsFormatted, ['value'], ['asc']));
      }
      return modelYearsFormatted;
    }

    return Array.from({ length: currentYear - 1956 }, (_value, index) => {
      const comingYears = (currentYear - index).toString();
      return {
        value: comingYears,
        label: comingYears,
      };
    });
  };

  let years = calcIntervalYear();
  years = _orderBy(years, ['value'], ['asc']);

  const changeIntervalStartYear = (e: any) => {
    getValues('initialYear', e);
    const selectedYear = e.value.length ? e.value : 1956;
    setYearLimit(_filter(years, values => Number(values.value) >= selectedYear));
    setInitialYear(e);
  };

  const changeIntervalEndYear = (e: any) => {
    getValues('finalYear', e);
    setFinalYear(e);
  };

  const start = _get(selectedFilter, 'between.start');
  const end = _get(selectedFilter, 'between.end');

  const selected = initialYear
    ? initialYear
    : start && {
        label: start,
        value: start,
      };

  const selectedFinal = finalYear
    ? finalYear
    : end && {
        label: end,
        value: end,
      };

  const handleFocusSelect = (e: any) => {
    e.target.setAttribute('readonly', 'readonly');
  };

  return (
    <form>
      <FilterWrapper
        title="ANO DO MODELO"
        className="inline-flex justify-center"
        titleClass="pb8"
      />
      <div className="inline-flex justify-between w-100">
        <Select
          locationDropdown="#sidebar-search"
          className={styleSelect}
          name="startYear"
          title="De"
          options={years}
          onChange={changeIntervalStartYear}
          selected={selected || null}
          onFocus={isMobile ? handleFocusSelect : onFocus}
        />

        <Select
          className={styleSelect}
          locationDropdown="#sidebar-search"
          name="endYear"
          title="Até"
          options={yearsLimit}
          onChange={changeIntervalEndYear}
          selected={selectedFinal || null}
          onFocus={isMobile ? handleFocusSelect : onFocus}
        />
      </div>
    </form>
  );
};
