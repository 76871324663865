import React from 'react';
import cx from 'classnames';
import { Icon } from '../../Icons';
import styles from '../style.module.css';

interface Props {
  onClick: () => void;
}

export const FloatButtonSidebarCircle = ({ onClick }: Props) => {
  const btnCircle = cx('bn bg-red fixed right-8 z-5 white br-100 pa12', styles.btnCircle);
  return (
    <button className={btnCircle} type="button" onClick={onClick}>
      <Icon name="SvgIconSearch" className="pointer" />
    </button>
  );
};
