import React from 'react';
import { FilterSelectCategory } from './FilterSelectCategory';
import { FormCategoryFilter } from './Form';

interface Props {
  onClick: any;
  typeSearch: any;
  select?: boolean;
}
export const CategoryFilter = ({ onClick, typeSearch, select = false }: Props) => {
  return select ? (
    <FilterSelectCategory onClick={onClick} typeSearch={typeSearch} />
  ) : (
    <FormCategoryFilter onClick={onClick} typeSearch={typeSearch} />
  );
};
