import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { map, toPairs } from 'lodash';
import { unique, SelectType } from '../../../utils/functions';
import { FilterList } from '../../AsideSearchBarAndContent/types';
import { Form } from './Form';

interface Props {
  getValues: (type: keyof FilterList, values: string) => void;
  hasBrand: boolean;
  hasModel: boolean;
  hasVersion: boolean;
  brandName: any;
  modelName: any;
  versionName: any;
  filterList: any;
  typeSearch: number | string;
}

export const BrandModelVersion = ({
  getValues,
  hasBrand,
  hasModel,
  hasVersion,
  brandName,
  modelName,
  versionName,
  filterList,
  typeSearch,
}: Props) => {
  const [brands, setBrands] = useState<SelectType[]>([]);
  const [models, setModels] = useState<SelectType[]>([]);
  const [versions, setVersions] = useState<SelectType[]>([]);

  useEffect(() => {
    const brandMap = map(brandName, (items: any) => {
      const brand = toPairs(items)[0];
      return {
        label: brand[0],
        value: brand[0],
      };
    });

    setBrands(unique(brandMap));
  }, [brandName]);

  useEffect(() => {
    const modelMap = map(modelName, (items: any) => {
      const model = toPairs(items)[0];
      return {
        label: model[0],
        value: model[0],
      };
    });

    setModels(unique(modelMap));
  }, [modelName]);

  useEffect(() => {
    const versionMap = map(versionName, (items: any) => {
      const version = toPairs(items)[0];
      return {
        label: version[0],
        value: version[0],
      };
    });

    setVersions(unique(versionMap));
  }, [versionName]);

  const onSubmit = (type: any, values: any) => {
    getValues(type, values);
  };

  const initialValues = {
    brand: (filterList.brand && filterList.brand.eq) || '',
    model: (filterList.model && filterList.model.eq) || '',
    version: (filterList.version && filterList.version.eq) || '',
  };

  const render = (props: any) => (
    <Form
      title="brandModelVersion"
      brands={brands}
      getValues={onSubmit}
      hasBrand={hasBrand}
      hasModel={hasModel}
      hasVersion={hasVersion}
      models={models}
      versions={versions}
      typeSearch={typeSearch}
      brandName={filterList.brand && filterList.brand.eq}
      modelName={filterList.model && filterList.model.eq}
      {...props}
    />
  );

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={initialValues}
      render={render}
    />
  );
};
