import React from 'react';
import { Button } from '../../../components/Button';
import styles from '../style.module.css';

interface Props {
  searchAgain?: () => void;
  message?: string;
}

export const EmptyState = ({ searchAgain, message = 'Não encontramos nenhum veículo.' }: Props) => {
  const renderButton = () => {
    if (!searchAgain) {
      return null;
    }

    return (
      <div className="flex justify-center mt16 mt12-ns mt16-m mt16-l">
        <Button classButton={styles.buttonSearchAgain} onClick={searchAgain}>
          buscar novamente
        </Button>
      </div>
    );
  };

  const styleSection =
    'w-100 flex flex-column items-center pb16-ns pb8-m pb16-l mb48 mb72-m pb8-l mt32';

  return (
    <section className={styleSection}>
      <img
        src={`${process.env.FTP_IMAGENS}images/search/empty-state.png`}
        className={styles.imgEmptyState}
        alt="Ilustração para busca sem resultados."
      />
      <div className="mt12 mt32-ns pt4-m pt12-l tc">
        <h1 className="fw7-ns f26 f30-ns f36-m mt0 mb12 mb0-ns mb16-m mb16-l pt0-l abbey">
          Que pena!
        </h1>
        <h2 className="fw2 f16 f20-ns f26-m mb0 mt0 lh-paragraph-m lh-paragraph-l abbey">
          {message}
        </h2>
      </div>
      {renderButton()}
    </section>
  );
};
