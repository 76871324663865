import React from 'react';
import _head from 'lodash/head';
import { Select } from '../Select';

interface Props {
  onChange: (itemSelected: any) => void;
  vehicleType: number | string;
}

const opts = [
  { value: {}, notOrderBy: true, label: 'Mais relevantes' },
  { value: { price: 'asc' }, label: 'Menor preço' },
  { value: { price: 'desc' }, label: 'Maior preço' },
  { value: { modelYear: 'desc' }, label: 'Ano mais novo' },
  { value: { mileage: 'asc' }, label: 'Menor Km' },
];

const optsParts = [
  { value: {}, notOrderBy: true, label: 'Mais relevantes' },
  { value: { price: 'asc' }, label: 'Menor preço' },
  { value: { price: 'desc' }, label: 'Maior preço' },
];

export const OrderBy = ({ onChange, vehicleType }: Props) => {
  const defaultValue = _head(vehicleType === 8 ? optsParts : opts);
  return (
    <section className="mv40">
      <p className="ma0 f16 fw7 abbey lh-title pl4 mb8">CLASSIFICAR POR</p>
      <Select
        title="Ordenar"
        name="ordenar"
        onChange={onChange}
        selected={defaultValue}
        options={vehicleType === 8 ? optsParts : opts}
        locationDropdown="ordenar"
      />
    </section>
  );
};
