import React from 'react';
import { defaultTo, get } from 'lodash';
import { CheckBoxFilter } from '../../../components/CheckBoxFilter';
import {
  AGRICULTURAL_VEHICLE_NUMBER,
  BUSS_NUMBER,
  CARGO_VEHICLE_NUMBER,
  CAR_NUMBER,
} from '../../../utils/comparationFilters';
import { formatFiltersForCheckBox } from '../../../utils/formatFilters';
import { NAUTICAL_VEHICLE_NUMBER, PARTS_NUMBER, SERVICES_NUMBER } from '../../../utils/productType';
import { BrandModelVersion } from '../../Filter/BrandModelVersion';
import { CategoryFilter } from '../../Filter/CategoryFilter';
import { PartCategoryFilter } from '../../Filter/PartCategoryFilter';
import { StoreByCity } from '../../Filter/StoreByCity';
import { YearInterval } from '../../Filter/YearInterval';
import { vehicleTypeNameByNumber } from '../../VehicleRegistration/utils/functions';
import { ContentSidebarProps } from '../types';
import { DefaultContentSidebar } from './DefaultContentSidebar';
import { Divider } from './Divider';
import { FilterBetween } from './FilterBetween';
import { OptionalsFilter } from './OptionalsFilter';
import { SectionOptionalsCheckbox } from './SectionOptionalsCheckbox';
import { ServicesContentSidebar } from './ServicesContentSidebar';

export const ContentSidebar = ({
  filterList,
  filters,
  valueKm,
  valuePrice,
  countOld,
  countNew,
  selectedCategory,
  brandName,
  modelName,
  handleOnChangeBrandModelVersion,
  handleGetYearValues,
  ONLYTHESESDIVIDERHASMB4,
  setValuesKM,
  valueBussSeat,
  setValueBussSeat,
  setValuesPrice,
  valueMotorcycle,
  setValuesMotorcyle,
  handleStoreByCity,
  handleCheckboxFilters,
  handleFilterClick,
  sidebarOpen,
  handleClickSearch,
  handleChangeSearch,
  searchBrand,
  isLoading,
  initialValuesSearch,
  currentYear,
  handleGetParamsFromUrlAndSearch,
  clearSearchInputValue,
  services,
  changeLocality,
  handleClickPartType,
  onSetSidebarOpen,
  handleClickPartCategorie,
}: ContentSidebarProps) => {
  const vehicleNumber = get(filterList, 'type.eq', 1);

  const { minKm, maxKm } = valueKm;
  const { minPrice, maxPrice } = valuePrice;

  const startMileage = get(filterList, 'mileage.between.start', '');
  const endMileage = get(filterList, 'mileage.between.end', '');
  const optionals = get(filters, 'optionals', []);

  const filterOptionsUserType = [
    { textLabel: 'Particular', value: '1' },
    { textLabel: 'Loja', value: '2' },
  ];

  const filterOptionsCondition = [
    { qtd: countNew, textLabel: 'Novo', value: '0 KM' },
    { qtd: countOld, textLabel: 'Usado', value: 'Usado' },
  ];

  const filterOptionsPartsCondition = [
    { qtd: countNew, textLabel: 'Nova', value: true },
    { qtd: countOld, textLabel: 'Usada', value: false },
  ];

  const renderBussSeat = () => {
    const { minBussSeat, maxBussSeat } = valueBussSeat;
    const normalizedSelectedCategory = vehicleTypeNameByNumber(selectedCategory);

    if (normalizedSelectedCategory !== 'onibus') {
      return null;
    }

    return (
      <>
        <FilterBetween
          title="QUANTIDADE DE ASSENTOS"
          min={minBussSeat}
          max={maxBussSeat}
          setValue={setValueBussSeat}
          range={[15, 99]}
        />
        <Divider />
      </>
    );
  };

  const renderMotorcycle = () => {
    const { minMotorcycle, maxMotorcycle } = valueMotorcycle;
    const normalizedSelectedCategory = vehicleTypeNameByNumber(selectedCategory);

    if (normalizedSelectedCategory === 'motos') {
      return (
        <>
          <FilterBetween
            title="CILINDRADAS"
            min={minMotorcycle || get(filterList, 'motor.between.start', '')}
            max={maxMotorcycle || get(filterList, 'motor.between.end', '')}
            setValue={setValuesMotorcyle}
          />
          {ONLYTHESESDIVIDERHASMB4}
        </>
      );
    }

    return null;
  };

  const renderTransmission = () => {
    const transmission = defaultTo(get(filters, 'transmission'), []);
    if (
      [
        CAR_NUMBER,
        BUSS_NUMBER,
        CARGO_VEHICLE_NUMBER,
        NAUTICAL_VEHICLE_NUMBER,
        AGRICULTURAL_VEHICLE_NUMBER,
      ].includes(selectedCategory) &&
      transmission.length > 0
    ) {
      return (
        <>
          <Divider />
          <CheckBoxFilter
            filterOptions={formatFiltersForCheckBox(transmission)}
            title="transmission"
            name="transmission"
            onChange={handleCheckboxFilters}
          />
        </>
      );
    }

    return null;
  };

  if (vehicleNumber === SERVICES_NUMBER) {
    return (
      <ServicesContentSidebar
        filterList={filterList}
        filters={filters}
        handleStoreByCity={handleStoreByCity}
        selectedCategory={selectedCategory}
        services={services}
        handleFilterClick={handleFilterClick}
        sidebarOpen={sidebarOpen}
        handleClickSearch={handleClickSearch}
        handleChangeSearch={handleChangeSearch}
        searchBrand={searchBrand}
        isLoading={isLoading}
        initialValuesSearch={initialValuesSearch}
        currentYear={currentYear}
        handleGetParamsFromUrlAndSearch={handleGetParamsFromUrlAndSearch}
        clearSearchInputValue={clearSearchInputValue}
        handleCheckboxFilters={handleCheckboxFilters}
        changeLocality={changeLocality}
        onSetSidebarOpen={onSetSidebarOpen}
      />
    );
  }

  if (vehicleNumber === PARTS_NUMBER) {
    return (
      <>
        <DefaultContentSidebar
          handleFilterClick={handleFilterClick}
          sidebarOpen={sidebarOpen}
          handleClickSearch={handleClickSearch}
          handleChangeSearch={handleChangeSearch}
          searchBrand={searchBrand}
          isLoading={isLoading}
          initialValuesSearch={initialValuesSearch}
          currentYear={currentYear}
          handleGetParamsFromUrlAndSearch={handleGetParamsFromUrlAndSearch}
          clearSearchInputValue={clearSearchInputValue}
          selectedCategory={selectedCategory}
          autocomplete={false}
          onSetSidebarOpen={onSetSidebarOpen}
        />
        <FilterBetween
          title="FAIXA DE PREÇO"
          isCurrency
          min={minPrice}
          max={maxPrice}
          setValue={setValuesPrice}
        />
        <Divider />
        <CategoryFilter
          onClick={handleClickPartType}
          typeSearch={filterList.modelType && filterList.modelType.eq}
          select
        />
        <Divider />
        <PartCategoryFilter
          onClick={handleClickPartCategorie}
          typeSearch={filterList?.part_categorie && filterList?.part_categorie?.in}
        />
        <Divider />
        <StoreByCity
          getValues={handleStoreByCity}
          hasStore={false}
          hasCity={!!filterList.city}
          selected={filterList}
          localities={get(filters, 'city', [])}
          typeSearch={vehicleNumber}
        />
        <Divider />
        <CheckBoxFilter
          filterOptions={filterOptionsPartsCondition}
          title="Condição"
          name="newPart"
          onChange={handleCheckboxFilters}
        />
        {/* <Divider />
        <SectionOptionalsCheckbox
          onChange={handleCheckboxFilters}
          filters={filters}
          typeSearch={selectedCategory}
        /> */}
      </>
    );
  }

  return (
    <>
      <DefaultContentSidebar
        handleFilterClick={handleFilterClick}
        sidebarOpen={sidebarOpen}
        handleClickSearch={handleClickSearch}
        handleChangeSearch={handleChangeSearch}
        searchBrand={searchBrand}
        isLoading={isLoading}
        initialValuesSearch={initialValuesSearch}
        currentYear={currentYear}
        handleGetParamsFromUrlAndSearch={handleGetParamsFromUrlAndSearch}
        clearSearchInputValue={clearSearchInputValue}
        selectedCategory={selectedCategory}
        onSetSidebarOpen={onSetSidebarOpen}
      />
      <BrandModelVersion
        getValues={handleOnChangeBrandModelVersion}
        hasBrand={!!filterList.brand}
        hasModel={!!filterList.model}
        hasVersion={!!filterList.version}
        brandName={brandName}
        modelName={modelName}
        versionName={filters && filters.version}
        filterList={filterList}
        typeSearch={selectedCategory}
      />
      <Divider />
      <YearInterval
        getFieldValues={handleGetYearValues}
        selectedFilter={filterList.modelYear}
        modelYears={filters && filters.modelYear}
      />
      {ONLYTHESESDIVIDERHASMB4}
      <FilterBetween
        title={selectedCategory === NAUTICAL_VEHICLE_NUMBER ? 'HORAS DE USO' : 'QUILOMETRAGEM'}
        min={minKm || startMileage}
        max={maxKm || endMileage}
        setValue={setValuesKM}
        maxLength={7}
      />
      <Divider />
      {renderBussSeat()}
      <FilterBetween
        title="FAIXA DE PREÇO"
        isCurrency
        min={minPrice}
        max={maxPrice}
        setValue={setValuesPrice}
      />
      {ONLYTHESESDIVIDERHASMB4}
      {renderMotorcycle()}
      <StoreByCity
        getValues={handleStoreByCity}
        hasStore={!!filterList.fantasyName}
        hasCity={!!filterList.city}
        selected={filterList}
        localities={get(filters, 'city', [])}
      />
      {renderTransmission()}
      <Divider />
      <CheckBoxFilter
        filterOptions={filterOptionsUserType}
        title="Vendedor"
        name="userType"
        onChange={handleCheckboxFilters}
      />
      <Divider />
      <CheckBoxFilter
        filterOptions={filterOptionsCondition}
        title="Condição"
        name="stateVehicle"
        onChange={handleCheckboxFilters}
      />
      <Divider />
      <SectionOptionalsCheckbox
        onChange={handleCheckboxFilters}
        filters={filters}
        typeSearch={selectedCategory}
      />
      <OptionalsFilter
        typeSearch={selectedCategory.toString()}
        dataOptionals={optionals}
        handleChange={handleCheckboxFilters}
      />
    </>
  );
};
