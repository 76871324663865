interface FilterOption {
  value: string;
  qtd: number;
  textLabel: string;
}

type ApiOptions = Record<string, number>[];

export const formatFiltersForCheckBox = (apiOptions: ApiOptions): FilterOption[] =>
  apiOptions.map((option: Record<string, number>) => {
    const keyName = Object.keys(option)[0];

    return {
      textLabel: keyName,
      qtd: option[keyName],
      value: keyName,
    };
  });
