import React from 'react';
import { FormYearInterval } from './Form';

interface Option {
  value: string;
  label: string;
}

interface Props {
  getFieldValues: (type: string, e: Option) => void;
  modelYears: any;
  selectedFilter: any;
}

export const YearInterval = ({ getFieldValues, modelYears, selectedFilter }: Props) => {
  return (
    <FormYearInterval
      getValues={getFieldValues}
      modelYears={modelYears}
      selectedFilter={selectedFilter}
    />
  );
};
