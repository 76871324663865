import React, { FormEvent, useEffect } from 'react';
import cx from 'classnames';
import { Field } from 'formik';
import { FilterWrapper } from '../../../components/FilterWrapper';
import { Select } from '../../../components/Select';
import { SelectType, toLabelValue } from '../../../utils/functions';
import styles from './style.module.css';

const isMobile = typeof window !== 'undefined' ? window.innerWidth < 768 : false;

interface Props {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  brands: SelectType[];
  values: any;
  title: string;
  getValues: (type: string, values: string) => void;
  hasBrand: boolean;
  hasModel: boolean;
  hasVersion: boolean;
  models?: any;
  versions?: any;
  typeSearch: number | string;
  brandName: string;
  modelName: string;
  onFocus: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
}

export const Form = ({
  setFieldValue,
  values,
  title,
  handleSubmit,
  getValues,
  brands,
  hasBrand,
  hasModel,
  hasVersion,
  models,
  versions,
  brandName,
  typeSearch,
  modelName,
}: Props) => {
  const { brand, model, version } = values;

  useEffect(() => {
    if (!hasBrand) {
      setFieldValue('brand', null);
    }

    if (!hasModel) {
      setFieldValue('model', null);
    }

    if (!hasVersion) {
      setFieldValue('version', null);
    }
  }, [hasBrand, hasModel, hasVersion]);

  const versionOnChange = ({ value }: SelectType) => {
    getValues('version', value);
    setFieldValue('version', value);
  };

  const brandOnChange = ({ value }: SelectType) => {
    getValues('brand', value);
    setFieldValue('brand', value);
    setFieldValue('model', '');
    setFieldValue('version', '');
  };

  const modelOnChange = ({ value }: SelectType) => {
    getValues('model', value);
    setFieldValue('model', value);
    setFieldValue('version', '');
  };

  const newBrand = brand ? brand : brandName;
  const newModel = model ? model : modelName;

  const modelFadeClass = cx({
    [styles.fadeIn]: (models || modelName) && newBrand,
    [styles.fadeOut]: (!models && !modelName) || !newBrand,
  });

  const versionFadeClass = cx({
    [styles.fadeIn]: typeSearch === 1 && versions && newModel && newBrand,
    [styles.fadeOut]: typeSearch !== 1 || !versions || !newModel || !newBrand,
  });

  const brandFadeClass = cx({
    [styles.fadeIn]: brands,
    [styles.fadeOut]: !brands,
  });

  const handleFocusSelect = (e: any) => {
    e.target.setAttribute('readonly', 'readonly');
  };

  const renderSelect = (props: any) => {
    return <Select {...props} onFocus={isMobile ? handleFocusSelect : props.onFocus} />;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={brandFadeClass}>
        <FilterWrapper title="MARCA">
          <Field
            locationDropdown="#sidebar-search"
            name="brand"
            title="Marca"
            value={newBrand ? toLabelValue(newBrand) : ''}
            isFormik
            options={brands}
            component={renderSelect}
            className="mb16 mt8"
            id={title + 'brand'}
            onChange={brandOnChange}
            selected={hasBrand ? toLabelValue(newBrand) : ''}
          />
        </FilterWrapper>
      </div>
      <div className={modelFadeClass}>
        <FilterWrapper title="MODELO">
          <Field
            locationDropdown="#sidebar-search"
            name="model"
            value={newModel ? toLabelValue(newModel) : ''}
            title="Modelo"
            isFormik
            component={renderSelect}
            className="mb16 mt8"
            id={title + 'model'}
            options={models}
            onChange={modelOnChange}
            selected={hasModel ? toLabelValue(newModel) : ''}
          />
        </FilterWrapper>
      </div>
      <div className={versionFadeClass}>
        <FilterWrapper title="VERSÃO">
          <Field
            locationDropdown="#sidebar-search"
            name="version"
            title="Versão"
            value={version ? toLabelValue(version) : ''}
            isFormik
            component={renderSelect}
            className="mb32 mt8"
            id={title + 'version'}
            options={versions}
            onChange={versionOnChange}
            selected={hasVersion ? toLabelValue(version) : ''}
          />
        </FilterWrapper>
      </div>
    </form>
  );
};
