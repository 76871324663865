import React from 'react';
import cx from 'classnames';
import { LinkBlack } from '../../../../Link';

export interface IMyStore {
  value: string;
  link: string;
  borderBottom?: boolean;
}

export const MyStore = ({ value, link, borderBottom = true }: IMyStore) => {
  const cxMyStore = cx('pb32-ns b--gray', {
    'bb-ns': borderBottom,
    'bb-m bb-l': !borderBottom,
  });

  return (
    <li className="pt4 pt12-ns pb12 pb0-ns ph16">
      <div className={cxMyStore}>
        <LinkBlack className="red no-underline lh-copy f13 fw7" to={link}>
          {value}
        </LinkBlack>
      </div>
    </li>
  );
};
