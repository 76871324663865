import React from 'react';
import { FloatButtonSideBar } from '../../../components/FloatButton';
import { Search } from '../../../components/RefineSearch/Search';
import { CategoryFilter } from '../../Filter/CategoryFilter';
import { DefaultContentSidebarProps } from '../types';
import { Divider } from './Divider';

export const DefaultContentSidebar = ({
  sidebarOpen,
  handleClickSearch,
  handleChangeSearch,
  selectedCategory,
  searchBrand,
  isLoading,
  initialValuesSearch,
  currentYear,
  handleGetParamsFromUrlAndSearch,
  clearSearchInputValue,
  autocomplete,
  onSetSidebarOpen,
}: DefaultContentSidebarProps) => {
  return (
    <>
      <FloatButtonSideBar onClick={onSetSidebarOpen} sidebarOpen={sidebarOpen} />
      <Search
        placeholder="Pesquise por marca e/ou modelo"
        handleClickSearch={handleClickSearch}
        onChange={handleChangeSearch}
        aria-label="Informe a marca ou modelo do veículo que deseja"
        selectedCategory={selectedCategory}
        locationDropdown="#sidebar-search"
        border
        value={searchBrand}
        loading={isLoading}
        searchBrand={searchBrand}
        values={initialValuesSearch}
        currentYear={currentYear}
        sidebarSearch
        handleGetParamsFromUrlAndSearch={handleGetParamsFromUrlAndSearch}
        clearSearchInputValue={clearSearchInputValue}
        isAutocomplete={autocomplete}
        onSetSidebarOpen={onSetSidebarOpen}
      />
      <Divider />
      <CategoryFilter onClick={handleClickSearch} typeSearch={selectedCategory} />
      <Divider />
    </>
  );
};
