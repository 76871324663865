import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import styles from './style.module.css';

export * from './components';

interface Props {
  hasShadow?: boolean;
  isMyAds?: boolean;
  cssStyles?: string;
}

export const Card: FunctionComponent<Props> = ({ isMyAds, hasShadow, children, cssStyles }) => {
  const cls = cx('w-100 br10 trueno bg-white flex flex-column pointer noPrint mr24', cssStyles, {
    'br--bottom br0-ns': isMyAds,
    [styles.shadow]: hasShadow,
  });

  return <section className={cls}>{children}</section>;
};
