import React, { PureComponent } from 'react';
import cx from 'classnames';
import clone from 'lodash/clone';
import debounce from 'lodash/debounce';
import _endsWith from 'lodash/endsWith';
import _filter from 'lodash/filter';
import find from 'lodash/find';
import _flattenDeep from 'lodash/flattenDeep';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import _includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import _union from 'lodash/union';
import values from 'lodash/values';
import QueryString from 'query-string';
import Media from 'react-media';
import Modal from 'react-modal';
import Sidebar from 'react-sidebar';
import { Button } from '../../components/Button';
import { Container } from '../../components/Container';
import { Grid } from '../../components/Grid';
import { GridCell } from '../../components/GridCell';
import { LoaderCard, LoaderFullPage } from '../../components/Loaders';
import { OrderBy } from '../../components/OrderBy';
import { formatParamsSearch, handleSearch } from '../../components/RefineSearch/utils';
import { SearchWithChips } from '../../components/SearchWithChips';
import { compareTitles } from '../../utils/comparationFilters';
import { removeAccent } from '../../utils/functions';
import { removeMaskMoney } from '../../utils/mask';
import {
  MOTORCYCLE_NUMBER,
  SERVICES_NUMBER,
  CAR_NUMBER,
  PARTS_NUMBER,
} from '../../utils/productType';
import { Footer } from '../Footer';
import { SectionBanner } from '../Home/components/SectionBanner';
import { Menu } from '../Menu';
import { Top } from '../Menu/components/MenuAd';
import { Services } from '../Services';
import {
  vehicleTypeNumberByName,
  vehicleTypeNameByNumber,
} from '../VehicleRegistration/utils/functions';
import { BtnMoreCards } from './components/BtnMoreCards';
import { Cards } from './components/Cards';
import { ContentSidebar } from './components/ContentSidebar';
import { EmptyState } from './components/EmptyState';
import { FilterButtons } from './components/FilterButtons';
import { AdvertisementResponse, AdvertisementPartServices } from './services';
import styles from './style.module.css';
import {
  CheckBoxFiltersEvent,
  QueryParams,
  StatesFilter,
  ValueMotorCycle,
  FilterList,
  Range,
  Filter,
  RangeFilter,
} from './types';
import { servicesStoreAdapter, removeLastBarOnUrl } from './utils/adapters';
import { deletSpecificFilters, unCheckedAllCheckBox } from './utils/asideSearchBarUtils';

const listener = {
  matches: true,
  addListener: () => null,
  removeListener: () => null,
};

const MQL = typeof window !== 'undefined' ? window.matchMedia('(min-width: 1024px)') : listener;

const dockedMQL =
  typeof window !== 'undefined' ? window.matchMedia('(min-width: 1280px)') : listener;

export class AsideSearchBarAndContent extends PureComponent<any, StatesFilter> {
  state: StatesFilter = {
    searchValue: '',
    sidebarDocked: MQL.matches,
    sidebarOpen: true,
    collapseSidebar: true,
    requestData: {},
    filterList: { type: { eq: CAR_NUMBER } },
    responseData: [],
    responseServicesData: [],
    dispatchFilter: false,
    page: 0,
    showButtonLoadMore: true,
    isLoading: true,
    responseNoData: false,
    filters: null,
    lastFilters: null,
    amountFound: 0,
    loaderFull: true,
    valueKm: { minKm: null, maxKm: null },
    valuePrice: { minPrice: '', maxPrice: '' },
    valueBussSeat: { minBussSeat: '', maxBussSeat: '' },
    loadingBtn: false,
    countNew: 0,
    countOld: 0,
    order: null,
    typeSearch: 'carros',
    valueMotorcycle: { minMotorcycle: '', maxMotorcycle: '' },
    savedStateWhenAsideOpen: {
      filterList: { type: { eq: CAR_NUMBER } },
      filters: null,
      lastFilters: null,
      valuePrice: { minPrice: '', maxPrice: '' },
      isLoading: true,
      searchValue: '',
      valueKm: { minKm: null, maxKm: null },
      valueMotorcycle: { minMotorcycle: null, maxMotorcycle: null },
      valueBussSeat: { minBussSeat: '', maxBussSeat: '' },
      typeSearch: 'carros',
      searchBrand: '',
      selectedFilterKey: '',
      lastItemSelected: [],
      lastBrands: null,
      lastModels: null,
    },
    searchBrand: '',
    selectedFilterKey: '',
    clearSearchInputValue: false,
    lastItemSelected: [],
    lastBrands: null,
    lastModels: null,
    partCategories: [],
    partSubCategories: [],
    partSubSubCategories: [],
  };

  ONLYTHESESDIVIDERHASMB4 = (
    <div className={cx('mt24 bt b--boulder mb32 o-30', styles.divider)} />
  );

  sectionCardRef: any = React.createRef();

  componentDidMount() {
    if (isEmpty(this.queryParams()) && typeof window !== 'undefined') {
      window.history.replaceState(null, '', '/anuncios/busca/?categoria=carros');
    }

    this.handleGetParamsFromUrlAndSearch();

    window.addEventListener(
      'popstate',
      () => {
        const search = window.location.search;
        const params = QueryString.parse(search);
        unCheckedAllCheckBox('_hasChecked');
        this.handleSetFilterList(params, undefined, true);
      },
      false,
    );
  }

  queryParams = (): QueryParams => {
    const search = typeof window !== 'undefined' ? window.location.search : '';
    const newSearch = removeLastBarOnUrl(search);

    const { filterList } = this.state;

    const params = QueryString.parse(newSearch);

    const currentCategory = get(filterList.type, 'eq', 1);

    const queryCategory = get(params, 'categoria', 'carros');

    const vehicleType = vehicleTypeNumberByName(removeAccent(queryCategory));

    if (vehicleType !== currentCategory) {
      filterList.type.eq = vehicleType;
      this.handleGetParamsFromUrlAndSearch();
    }
    const getCustomTitle = () => {
      const marca = params.marca ? `${params.marca}` : '';
      const modelo = params.modelo ? `${params.modelo}` : '';
      const cidade = params.cidade ? ` em ${params.cidade}` : '';
      const title = marca ? `${marca} ${modelo} ${cidade}` : '';
      return title.replace(' ', ' ') ? title : this.props.defaultTitle;
    };
    this.props.setCustomTitle(getCustomTitle());
    return params;
  };

  UNSAFE_componentWillMount() {
    MQL.addListener(this.mediaQueryChanged);
    if (MQL.matches) {
      return null;
    }

    const queryParams = this.queryParams();

    const filterKeys = Object.keys(queryParams);

    if (_includes(filterKeys, 'categoria') && filterKeys.length > 1) {
      this.onSetSidebarOpen(false);
      this.onDocked(false);
      return;
    }

    this.onSetSidebarOpen(true);
    this.onDocked(true);
  }

  UNSAFE_componentWillUnmount() {
    MQL.removeListener(this.mediaQueryChanged);
  }

  handleSetFilterOptionals = (
    list: any,
    optionals: string[] | unknown[],
    isService?: boolean,
  ): any => {
    const key = isService ? 'services' : 'optionals';

    const optionalsInclude = get(list, `${key}.include`, []);

    const include = _flattenDeep(_union(optionalsInclude, optionals));

    return {
      ...list,
      [key]: {
        include,
      },
    };
  };

  handleSetFilterParts = (list: any, key: string, value: string): any => {
    if (key === 'newPart') {
      const type = value === 'true' ? 'Usada' : 'Nova';
      this.handleChangeCheckbox(type, false);
    }

    return {
      ...list,
      [key]: {
        eq: value,
      },
    };
  };

  handleSetFilterOptionalsNotInclude = (list: any, notIncludeOptional: string[]): any => {
    const include = get(list, 'optionals.include', []);

    if (include.length) {
      return {
        ...list,
        optionals: {
          include,
          not: {
            include: notIncludeOptional,
          },
        },
      };
    }

    return {
      ...list,
      optionals: {
        not: {
          include: notIncludeOptional,
        },
      },
    };
  };

  handleSetFilterUnique = (list: any, options: { key: string; eq: string | number }): any => {
    const { key, eq } = options;

    return {
      ...list,
      [key]: {
        eq,
      },
    };
  };

  handleSetFilterBetween = (
    list: any,
    options: { key: string; start: number | string | null; end?: number | string | null },
  ): any => {
    const { key, start, end } = options;

    return {
      ...list,
      [key]: {
        between: {
          start,
          end,
        },
      },
    };
  };

  handleSetFilterCity = (list: any, options: { city: string; state: string }): any => {
    const { city, state } = options;

    list = this.handleSetFilterUnique(list, { key: 'city', eq: city });
    list = this.handleSetFilterUnique(list, { key: 'state', eq: state });

    return list;
  };

  handleSetFilterIn = (list: any, options: { key: string; option: string[] }) => {
    const { key, option } = options;

    return {
      ...list,
      [key]: {
        in: option,
      },
    };
  };

  handleSetFilterInOrUnique = (list: any, options: { key: string; value: string[] }): any => {
    const { key, value } = options;

    if (value.length > 1) {
      return this.handleSetFilterIn(list, { key, option: value });
    }

    return this.handleSetFilterUnique(list, { key, eq: value[0] });
  };

  handleSetVehicleCaracteristics(name: string, value: string, list: any) {
    let checkedValue = value.split('_');

    const eq = get(list[name], 'eq', []);
    const inc = get(list[name], 'in', []);

    if (eq === value) {
      return list;
    }

    if (eq.length) {
      checkedValue = [eq, value];
    }

    if (inc.length) {
      checkedValue = [...inc, value];
    }

    list = this.handleSetFilterInOrUnique(list, {
      key: name,
      value: checkedValue,
    });

    return list;
  }

  handleSetFilterList = (queryParams?: QueryParams, loadMore?: string, useDefaultState = false) => {
    let { valuePrice, valueBussSeat, typeSearch, filterList } = this.state;
    filterList = useDefaultState ? { type: { eq: CAR_NUMBER } } : filterList;

    const queryOptionals: string = get(queryParams, 'opcionais', '');
    const queryCategory: string = removeAccent(get(queryParams, 'categoria', '')).toLowerCase();
    const queryBrand: string = get(queryParams, 'marca', '');
    const queryModel: string = get(queryParams, 'modelo', '');
    const queryVersion: string = get(queryParams, 'versao', '');
    const queryModelYear: string = get(queryParams, 'ano_modelo', '');
    const queryMileage: string = get(queryParams, 'quilometragem', '');
    const queryPrice: string = get(queryParams, 'preco', '');
    const queryCity: string = get(queryParams, 'cidade', '');
    const queryUserType: string = get(queryParams, 'vendedor', '');
    const queryTransmission: string = get(queryParams, 'transmissao', '');
    const queryFuelType: string = get(queryParams, 'combustivel', '');
    const queryColor: string = get(queryParams, 'cor', '');
    const queryPorts: string = get(queryParams, 'portas', '');
    const queryMotor: string = get(queryParams, 'motor', '');
    const queryStore: string = get(queryParams, 'loja', '');
    const queryStateVehicle: string = get(queryParams, 'condicao', '');
    const querySearch: string = get(queryParams, 'pesquisar', '');
    const queryMarches: string = get(queryParams, 'marchas', '');
    const queryBodyStyle: string = get(queryParams, 'tipo', '');
    const queryBussSeats: string = get(queryParams, 'assentos', '');
    const queryServices: string = get(queryParams, 'services', '');
    const queryNewPart: string = get(queryParams, 'condição da peça', '');
    const queryTypePart: string = get(queryParams, 'tipo de peça', '');
    const queryModelType: string = get(queryParams, 'categoria da peça');
    const queryPartCategorie: string = get(queryParams, 'categoriaPeca', '');

    if (queryNewPart) {
      filterList = this.handleSetFilterUnique(filterList, { key: 'newPart', eq: queryNewPart });
    }

    if (queryTypePart) {
      this.handleChangeCheckbox(queryTypePart, true);
      filterList = this.handleSetFilterUnique(filterList, { key: 'partType', eq: queryTypePart });
    }

    if (queryModelType) {
      filterList = this.handleSetFilterUnique(filterList, { key: 'modelType', eq: queryModelType });
    }

    if (queryOptionals) {
      const optionals = queryOptionals.split('_');

      filterList = this.handleSetFilterOptionals(filterList, optionals);
    }

    if (queryServices) {
      const services = queryServices.split('_');

      filterList = this.handleSetFilterOptionals(filterList, services, true);
    }

    if (queryCategory) {
      typeSearch = queryCategory;

      const category = vehicleTypeNumberByName(queryCategory);

      filterList = this.handleSetFilterUnique(filterList, { key: 'type', eq: category });
    }

    if (queryBrand) {
      filterList = this.handleSetFilterUnique(filterList, { key: 'brand', eq: queryBrand });
    }

    if (queryModel) {
      filterList = this.handleSetFilterUnique(filterList, { key: 'model', eq: queryModel });
    }

    if (queryVersion) {
      filterList = this.handleSetFilterUnique(filterList, { key: 'version', eq: queryVersion });
    }

    if (queryModelYear) {
      const between = queryModelYear.split(' a ');
      const start = parseInt(between[0], 10);
      const end = parseInt(between[1], 10);

      if (between.length) {
        filterList = this.handleSetFilterBetween(filterList, { key: 'modelYear', start, end });
      }
    }

    if (queryBussSeats) {
      const between = queryBussSeats.split(' a ');
      const start = parseInt(between[0], 10);
      const end = parseInt(between[1], 10);

      if (between.length) {
        const minBussSeat = between[0];
        const maxBussSeat = between[1];

        valueBussSeat = { minBussSeat, maxBussSeat };

        filterList = this.handleSetFilterBetween(filterList, { key: 'seatsAmount', start, end });
      }
    }

    if (queryMileage) {
      const between = queryMileage.replace(/km/g, '').split(' a ');
      const start = parseInt(between[0], 10);
      const end = parseInt(between[1], 10);

      if (between.length) {
        filterList = this.handleSetFilterBetween(filterList, { key: 'mileage', start, end });
      }
    }

    if (queryPrice) {
      const between = queryPrice
        .replace(/\./g, '')
        .replace(/R\$/g, '')
        .replace(/ /g, '')
        .split('a');

      const start = parseInt(between[0], 10);
      const end = parseInt(between[1], 10);

      if (between.length) {
        const minPrice = queryPrice.split(' a ')[0];
        const maxPrice = queryPrice.split(' a ')[1];

        valuePrice = {
          minPrice,
          maxPrice,
        };

        filterList = this.handleSetFilterBetween(filterList, { key: 'price', start, end });
      }
    }

    if (queryCity) {
      const city = queryCity.split('-');

      if (city.length) {
        const cityName = city[0];
        const state = city[1];

        filterList = this.handleSetFilterCity(filterList, { city: cityName, state });
      }
    }

    if (queryUserType) {
      const userTypes = queryUserType.split('_');
      const eq = compareTitles(queryUserType);

      if (userTypes.length === 1) {
        filterList = this.handleSetFilterUnique(filterList, {
          key: 'userType',
          eq,
        });
      }
    }

    if (queryTransmission) {
      filterList = this.handleSetVehicleCaracteristics(
        'transmission',
        queryTransmission,
        filterList,
      );
    }

    if (queryFuelType) {
      filterList = this.handleSetVehicleCaracteristics('fuelType', queryFuelType, filterList);
    }

    if (queryColor) {
      filterList = this.handleSetVehicleCaracteristics('color', queryColor, filterList);
    }

    if (queryPorts) {
      const ports = queryPorts.split('_');

      let normalizedPorts = '';

      if (ports.length === 1) {
        normalizedPorts = queryPorts.replace(/\D/g, '');
        filterList = this.handleSetVehicleCaracteristics('ports', normalizedPorts, filterList);
      }

      if (ports.length > 1) {
        forEach(ports, port => {
          filterList = this.handleSetVehicleCaracteristics('ports', port, filterList);
        });
      }
    }

    if (queryBodyStyle) {
      filterList = this.handleSetVehicleCaracteristics('bodyStyle', queryBodyStyle, filterList);
    }

    if (queryMotor && queryCategory === 'motos') {
      const between = queryMotor.split(' a ');
      const start = parseInt(between[0], 10);
      const end = parseInt(between[1], 10);

      filterList = this.handleSetFilterBetween(filterList, { key: 'motor', start, end });
    }

    if (queryMotor && queryCategory === 'carga') {
      filterList = this.handleSetVehicleCaracteristics('motor', queryMotor, filterList);
    }

    if (queryStore) {
      filterList = this.handleSetFilterUnique(filterList, {
        key: 'fantasyName',
        eq: queryStore,
      });
    }

    if (queryStateVehicle) {
      filterList = this.handleSetFilterListStateVehicle(filterList, queryStateVehicle);
      this.handleChangeCheckbox(queryStateVehicle, true);
    }

    if (querySearch) {
      filterList = {
        ...filterList,
        search: querySearch,
      };
      this.setState({ searchValue: querySearch });
    }

    if (queryMarches) {
      const marches = queryMarches.split('_');

      let normalizedMarches = '';

      if (marches.length === 1) {
        normalizedMarches = queryMarches.replace(/\D/g, '');
        filterList = this.handleSetVehicleCaracteristics('marches', normalizedMarches, filterList);
      }

      if (marches.length > 1) {
        forEach(marches, march => {
          filterList = this.handleSetVehicleCaracteristics('marches', march, filterList);
        });
      }
    }

    if (queryPartCategorie) {
      filterList = {
        ...filterList,
        part_categorie: {
          in: [Number(queryPartCategorie)],
        },
      };
    }

    this.setState(
      {
        filterList,
        valuePrice,
        typeSearch,
        clearSearchInputValue: !!querySearch,
        valueBussSeat,
      },
      () => {
        this.handleRequestData(loadMore);
      },
    );
  };

  handleGetParamsFromUrlAndSearch = () => {
    const queryParams = this.queryParams();

    if (isEmpty(queryParams)) {
      return this.setState({ loaderFull: false });
    }

    this.handleSetFilterList(queryParams);
  };

  mediaQueryChanged = () => {
    this.setState({ sidebarDocked: MQL.matches });
  };

  onSetSidebarOpen = (collapse?: boolean) => {
    if (typeof collapse !== 'boolean') {
      collapse = !this.state.sidebarOpen;
    }

    this.setState({ sidebarOpen: collapse, collapseSidebar: collapse });
    const {
      filterList,
      valuePrice,
      isLoading,
      searchValue,
      valueKm,
      valueMotorcycle,
      typeSearch,
      filters,
      lastFilters,
      valueBussSeat,
      searchBrand,
      selectedFilterKey,
      lastItemSelected,
      lastBrands,
      lastModels,
    } = this.state;

    if (collapse) {
      this.setState({
        savedStateWhenAsideOpen: {
          filterList: clone(filterList),
          filters: clone(filters),
          lastFilters: clone(lastFilters),
          valuePrice: clone(valuePrice),
          isLoading,
          searchValue,
          valueKm: clone(valueKm),
          valueMotorcycle: clone(valueMotorcycle),
          valueBussSeat: clone(valueBussSeat),
          typeSearch,
          searchBrand,
          selectedFilterKey,
          lastItemSelected: clone(lastItemSelected),
          lastBrands: clone(lastBrands),
          lastModels: clone(lastModels),
        },
      });
    }
  };

  onDocked = (collapse: boolean) => {
    this.setState({ collapseSidebar: collapse });
  };

  handleFilterClick = (event: { stopPropagation: () => void }) => {
    const { sidebarOpen, collapseSidebar } = this.state;

    event.stopPropagation();

    this.onSetSidebarOpen(!sidebarOpen);
    this.onDocked(!collapseSidebar);

    const queryParams = this.queryParams();

    this.handleSetFilterList(queryParams);
  };

  handleNormalizeFilterListToRequest = (list: any) => {
    delete list.stateVehicle;

    const newFilterListOptionals = get(list, 'optionals.include', []);
    const newFilterListOptionalsNotInclude = get(list, 'optionals.not.include', []);

    const hasOptionals = newFilterListOptionals.length;
    const hasOptionalsNotInclude = newFilterListOptionalsNotInclude.length;

    if (hasOptionals) {
      const optionals = _flattenDeep(newFilterListOptionals);

      list = this.handleSetFilterOptionals(list, optionals);
    }

    if (hasOptionalsNotInclude) {
      list = this.handleSetFilterOptionalsNotInclude(list, newFilterListOptionalsNotInclude);
    }

    return list;
  };

  handleSetLastBrands = (brandName: any) => {
    this.setState({ lastBrands: brandName });
  };

  handleSetLastModels = (modelName: any) => {
    this.setState({ lastModels: modelName });
  };

  countFilter = (responseFilters: any) => {
    const { filterList } = this.state;

    const filterCount =
      filterList.type.eq === PARTS_NUMBER ? responseFilters.newPart : responseFilters.optionals;

    const filterCountType = filterList.type.eq === PARTS_NUMBER ? 'true' : '0 KM';

    return find(filterCount, (item: any) => {
      const keyFilter = (Object.keys(item)[0] || '').replace(/\"/g, '');
      if (keyFilter === filterCountType) {
        return item;
      }
    });
  };

  handleRequestAdvertisementResponseSuccess = (response: any, loadMore?: string) => {
    const { filterList, countNew, page, lastFilters, selectedFilterKey } = this.state;

    let count = 0;
    let copyCountNew = clone(countNew);

    const firstPage = page === 0;

    const resOk = get(response, 'status', false) === 200;
    const data = get(response, 'data.data', []);
    const responseFilters = get(response, 'data.filters', {});

    const contentRange = get(response, 'headers.content-range', 0);
    count = parseInt(contentRange, 10);

    copyCountNew = this.countFilter(responseFilters);

    const countByValue = values(copyCountNew)[0] || 0;

    const countOld = count - countByValue;

    if (lastFilters) {
      responseFilters[selectedFilterKey] = lastFilters[selectedFilterKey];
    }

    if (!filterList.brand) {
      this.handleSetLastBrands(responseFilters.brandName);
    }

    if (filterList.brand && !filterList.model) {
      this.handleSetLastModels(responseFilters.modelName);
    }

    this.setState(
      ({ responseData }) => {
        return {
          filterList,
          loaderFull: false,
          isLoading: false,
          loadingBtn: false,
          amountFound: count,
          countNew: countByValue,
          countOld,
          filters: responseFilters,
          lastFilters: responseFilters,
          showButtonLoadMore: data.length >= 12 && count > 12 && count / 12 > page + 1,
          responseData: firstPage || !loadMore ? data : responseData.concat(data),
          responseNoData: !resOk,
        };
      },
      () => {
        const userTypeEq = get(filterList, 'userType.eq', null);

        const queryParams = this.queryParams();

        const queryStateVehicle: string = get(queryParams, 'condicao', '');
        const queryOptionals: string = get(queryParams, 'opcionais', '');
        const queryTransmission: string = get(queryParams, 'transmissao', '');
        const queryFuelType: string = get(queryParams, 'combustivel', '');
        const queryColor: string = get(queryParams, 'cor', '');
        const queryPorts: string = get(queryParams, 'portas', '');
        const queryMarches: string = get(queryParams, 'marchas', '');
        const queryBodyStyle: string = get(queryParams, 'tipo', '');
        const queryMotor: string = get(queryParams, 'motor', '');

        if (userTypeEq) {
          this.handleChangeCheckbox(`userType-${userTypeEq}`, true);
        }

        if (queryStateVehicle) {
          this.handleChangeCheckbox(queryStateVehicle, true);
        }

        if (queryTransmission) {
          const transmissions = queryTransmission.split('_');

          forEach(transmissions, transmission => {
            this.handleChangeCheckbox(transmission, true);
          });
        }

        if (queryFuelType) {
          const fuelTypes = queryFuelType.split('_');

          forEach(fuelTypes, fuelType => {
            this.handleChangeCheckbox(fuelType, true);
          });
        }

        if (queryColor) {
          const colors = queryColor.split('_');

          forEach(colors, color => {
            this.handleChangeCheckbox(color, true);
          });
        }

        if (queryPorts) {
          const ports = queryPorts.split('_');

          if (ports.length === 1) {
            this.handleChangeCheckbox(ports[0], true);
          }

          if (ports.length > 1) {
            forEach(ports, port => {
              this.handleChangeCheckbox(`${port} portas`, true);
            });
          }
        }

        if (queryOptionals) {
          const optionals = queryOptionals.split('_');

          forEach(optionals, optional => {
            this.handleChangeCheckbox(optional, true);
          });
        }

        if (queryMarches) {
          const marches = queryMarches.split('_');

          if (marches.length === 1) {
            this.handleChangeCheckbox(marches[0], true);
          }

          if (marches.length > 1) {
            forEach(marches, march => {
              this.handleChangeCheckbox(`${march} marchas`, true);
            });
          }
        }

        if (queryBodyStyle) {
          const bodyStyles = queryBodyStyle.split('_');

          forEach(bodyStyles, bodyStyle => {
            this.handleChangeCheckbox(bodyStyle, true);
          });
        }

        if (queryMotor) {
          const motors = queryMotor.split('_');

          forEach(motors, motor => {
            this.handleChangeCheckbox(motor, true);
          });
        }
      },
    );
  };

  handleRequestAdvertisementPartServicesSuccess = (
    response: any,
    order: string,
    loadMore?: string,
  ) => {
    const { filterList, countNew, page } = this.state;

    let count = 0;
    let copyCountNew = clone(countNew);

    const firstPage = page === 0;

    const resOk = get(response, 'status', false) === 200;
    const data = get(response, 'data.data', []);
    const responseFilters = get(response, 'data.filters', {});

    const contentRange = get(response, 'headers.content-range', 0);
    count = parseInt(contentRange, 10);

    copyCountNew = this.countFilter(responseFilters);

    const countByValue = values(copyCountNew)[0] || 0;

    const countOld = count - countByValue;

    this.setState(
      ({ responseServicesData }) => {
        return {
          filterList,
          loaderFull: false,
          isLoading: false,
          loadingBtn: false,
          amountFound: count,
          countNew: countByValue,
          countOld,
          filters: responseFilters,
          showButtonLoadMore: data.length >= 12 && count > 12 && count / 12 > page + 1,
          responseServicesData:
            firstPage || (order && !loadMore) ? data : responseServicesData.concat(data),
          responseNoData: !resOk,
        };
      },
      () => {
        const queryParams = this.queryParams();

        const queryServices: string = get(queryParams, 'services', '');
        const queryPartType: string = get(queryParams, 'tipo de peça', '');
        const queryNewPart: string = get(queryParams, 'condição da peça', '');
        const queryModelType: string = get(queryParams, 'categoria da peça');

        if (queryServices) {
          const services = queryServices.split('_');

          forEach(services, service => {
            this.handleChangeCheckbox(service, true);
          });
        }

        if (queryNewPart) {
          const id = queryNewPart === 'false' ? 'Usada' : 'Nova';

          this.handleChangeCheckbox(id, true);
        }

        if (queryModelType) {
          this.handleChangeCheckbox(queryModelType, true);
        }

        if (queryPartType) {
          this.handleChangeCheckbox(queryPartType, true);
        }
      },
    );
  };

  handleRequestData = async (loadMore?: string) => {
    const { filterList, order, searchValue } = this.state;

    let { page } = this.state;

    const isServices = get(filterList, 'type.eq', CAR_NUMBER) === SERVICES_NUMBER;

    if (isServices) {
      const normalizedFilterList = clone(filterList);

      delete normalizedFilterList.type;
      delete normalizedFilterList.search;

      try {
        const res = await AdvertisementPartServices.getAdvertisementPartServices(
          normalizedFilterList,
          page,
          searchValue,
        );

        if (get(res, 'status') !== 200) {
          this.setState({
            isLoading: false,
            loadingBtn: false,
            responseData: [],
            responseServicesData: [],
            responseNoData: true,
            amountFound: 0,
            loaderFull: false,
          });

          console.error('Erro ao realizar busca!');
        }

        return this.handleRequestAdvertisementPartServicesSuccess(res, order, loadMore);
      } catch (e) {
        console.error(e);
        this.setState({
          isLoading: false,
          loadingBtn: false,
          responseData: [],
          responseServicesData: [],
          responseNoData: true,
          amountFound: 0,
          loaderFull: false,
        });
      }
    }

    let take = 0;

    if (order && !loadMore) {
      take = 12 * page;
      page = 0;
    }

    if (loadMore && order) {
      take = 12;
    }

    if (!loadMore && !order) {
      page = 0;
    }

    let newFilterList = clone(filterList);

    newFilterList = this.handleNormalizeFilterListToRequest(newFilterList);

    this.setState({ isLoading: page === 0 });

    const isParts = get(filterList, 'type.eq', PARTS_NUMBER) === PARTS_NUMBER;

    const typeSearch = isParts
      ? AdvertisementResponse.getAdvertisementPartResponse
      : AdvertisementResponse.getAdvertisementResponse;

    try {
      const response = await typeSearch(newFilterList, page, searchValue, order, take);

      if (response.status !== 200) {
        this.setState({
          isLoading: false,
          loadingBtn: false,
          responseData: [],
          responseServicesData: [],
          responseNoData: true,
          amountFound: 0,
        });
        console.error(`Erro ao realizar busca: ${response.status}, ${response}`);
      }

      this.handleRequestAdvertisementResponseSuccess(response, loadMore);
    } catch (e) {
      this.setState({
        isLoading: false,
        loadingBtn: false,
        responseData: [],
        responseServicesData: [],
        responseNoData: true,
        amountFound: 0,
      });
      // console.error(e);
    }
  };

  handleRequestNextPage = () => {
    this.setState(
      ({ page }) => ({ page: page + 1, loadingBtn: true }),
      () => this.handleSetFilterList({ pesquisar: this.state.searchValue }, 'loadMore'),
    );
  };

  handleUniqueStateVehicle = (list: any, value: string, stateVehicle: string) => {
    if (stateVehicle === '0 KM') {
      const optionals = get(list, 'optionals.include', []);
      const hasOptionals = optionals.length;

      let optionalsInclude = [];

      if (hasOptionals) {
        optionalsInclude = clone(optionals);
      }

      delete list.optionals;

      return this.handleSetFilterOptionals(list, [...optionalsInclude, value]);
    }

    if (stateVehicle === 'Usado') {
      const opts = get(list, 'optionals.include', []);
      const hasOpts = opts.length;

      let optsInclude = [];

      if (hasOpts) {
        optsInclude = clone(opts);
      }

      delete list.optionals;

      const hasStateNew = _includes(optsInclude, '0 KM');

      if (hasStateNew) {
        optsInclude = _filter(optsInclude, (opt: string) => opt !== '0 KM');
      }

      if (optsInclude.length) {
        list = this.handleSetFilterOptionals(list, optsInclude);
      }

      return this.handleSetFilterOptionalsNotInclude(list, [value]);
    }

    return list;
  };

  handleSetFilterListStateVehicle = (list: any, value: string) => {
    const vehicleUsed = value === 'Usado';

    let notIncludeValue = '';
    let includeValue = '0 KM';
    let elementId = 'Usado';

    if (vehicleUsed) {
      notIncludeValue = '0 KM';
      includeValue = '';
      elementId = '0 KM';
    }

    if (includeValue) {
      list = this.handleUniqueStateVehicle(list, includeValue, value);
    }

    if (notIncludeValue) {
      list = this.handleUniqueStateVehicle(list, notIncludeValue, value);
    }

    list = this.handleSetFilterUnique(list, { key: 'stateVehicle', eq: value });

    this.handleChangeCheckbox(elementId, false);

    return list;
  };

  handleChangeCheckbox = (elementId: string, checked: boolean) => {
    let elId = elementId;

    const isPortChecked = _endsWith(elementId, 'portas');
    const isMarchesSelected = _endsWith(elementId, 'marchas');

    if (!isPortChecked && !isMarchesSelected) {
      elId = elementId.replace(/\s/g, '');
    }

    const element = document.getElementById(elId) as HTMLInputElement;

    if (element) {
      element.checked = checked;
    }
  };

  handleCheckUserType = (value: string, list: any) => {
    list = this.handleSetFilterUnique(list, { key: 'userType', eq: value });

    this.handleChangeCheckbox(`userType-${value}`, true);

    let elementId = 'userType-1';

    if (value === '1') {
      elementId = 'userType-2';
    }

    this.handleChangeCheckbox(elementId, false);

    return list;
  };

  handleCheckStateVehicle = (value: string, list: any) => {
    list = this.handleSetFilterListStateVehicle(list, value);

    this.handleChangeCheckbox(value, true);

    return list;
  };

  handleCheckOptionals = (value: string, list: any, isService?: boolean) => {
    const valueStateVehicleSelected = get(list, 'stateVehicle.eq', '');

    let includeOptionals = '';
    let notIncludeOptionals = '';

    if (valueStateVehicleSelected === '0 KM') {
      includeOptionals = '0 KM';
      notIncludeOptionals = '';
    }

    if (valueStateVehicleSelected === 'Usado') {
      includeOptionals = '';
      notIncludeOptionals = '0 KM';
    }

    if (includeOptionals) {
      list = this.handleSetFilterOptionals(list, [includeOptionals], isService);
    }

    if (notIncludeOptionals) {
      list = this.handleSetFilterOptionalsNotInclude(list, [notIncludeOptionals]);
    }

    if (value !== '0 KM' && value !== 'Usado') {
      list = this.handleSetFilterOptionals(list, [value], isService);
    }

    return list;
  };

  handleSaveLastFilters = (key: string) => {
    if (key === 'brand' || key === 'model') {
      key = `${key}Name`;
    }

    this.setState(({ filters }) => ({
      lastFilters: filters,
      selectedFilterKey: key,
    }));
  };

  handleCheck = (name: keyof FilterList, value: string) => {
    let { filterList } = this.state;

    const vehicleCaracteristicsFilters = [
      'transmission',
      'fuelType',
      'color',
      'ports',
      'marches',
      'bodyStyle',
      'motor',
    ];

    if (name === 'userType') {
      filterList = this.handleCheckUserType(value, filterList);
    }

    if (name === 'stateVehicle') {
      filterList = this.handleCheckStateVehicle(value, filterList);
    }

    if (_includes(vehicleCaracteristicsFilters, name)) {
      this.handleSaveLastFilters(name);

      filterList = this.handleSetVehicleCaracteristics(name, value, filterList);
    }

    if (name === 'optionals') {
      filterList = this.handleCheckOptionals(value, filterList);
    }

    if (name === 'services') {
      filterList = this.handleCheckOptionals(value, filterList, true);
    }

    if (name === 'partType') {
      filterList = this.handleSetFilterParts(filterList, name, value);
    }

    if (name === 'newPart') {
      filterList = this.handleSetFilterParts(filterList, name, value);
    }

    const lastItemSelected = [];
    lastItemSelected.push({ [name]: filterList[name] });

    this.setState({ filterList, lastItemSelected }, () => {
      this.handleSetFilterList({}, '');
    });
  };

  handleUncheck = (name: keyof FilterList, value: string, id: string) => {
    const { filterList } = this.state;

    filterList[name] = deletSpecificFilters(value, name, filterList) as Filter &
      RangeFilter &
      string;

    this.handleRemoveItemsCheckbox(name, id);

    const lastItemSelected = [];
    lastItemSelected.push({ [name]: filterList[name] });

    return this.setState({ filterList, lastItemSelected }, () => {
      this.handleSetFilterList({}, '');
    });
  };

  handleCheckboxFilters = (event: CheckBoxFiltersEvent) => {
    const { value, name, checked, id } = event.currentTarget;

    this.setState(
      {
        isLoading: true,
        page: 0,
      },
      () => {
        if (checked) {
          return this.handleCheck(name, value);
        }

        this.handleUncheck(name, value, id);
      },
    );
  };

  handleUnCheckAll = (options?: {
    searchFromQueryParams: boolean;
    userType?: number | null | string;
  }) => {
    const queryParams = this.queryParams();
    const typeSearch = get(queryParams, 'categoria', 'carros');

    const userType =
      options && options.userType && typeSearch !== 'servicos'
        ? { userType: { eq: options.userType } }
        : null;

    this.setState(
      {
        filterList: {
          type: { eq: vehicleTypeNumberByName(typeSearch) },
          ...userType,
        },
        typeSearch,
        valuePrice: { minPrice: '', maxPrice: '' },
        isLoading: true,
        searchValue: '',
        valueKm: { minKm: '', maxKm: '' },
        valueMotorcycle: { minMotorcycle: '', maxMotorcycle: '' },
        page: 0,
        clearSearchInputValue: true,
        lastFilters: null,
      },
      () => {
        unCheckedAllCheckBox('_hasChecked');

        if (get(options, 'searchFromQueryParams')) {
          return this.handleSetFilterList(queryParams);
        }

        this.handleSetFilterList();
      },
    );
  };

  handleRemoveItemsCheckbox = (name: keyof FilterList, checkboxSelected?: string) => {
    const { filterList } = this.state;

    let elementId = checkboxSelected || '';

    forEach(filterList, (_item, key) => {
      if (key === name) {
        if (isEmpty(filterList[key])) {
          delete filterList[key];
          return;
        }

        const uncheckedOptionals = _filter(
          _flattenDeep(filterList[name].include || []),
          optional => {
            return optional !== checkboxSelected;
          },
        );

        if (uncheckedOptionals.length) {
          (filterList[name] ?? {}).include = uncheckedOptionals;
        }
      }
    });

    if (checkboxSelected === 'Usado' || checkboxSelected === '0KM') {
      delete filterList.optionals;
      elementId = checkboxSelected;
    }

    if (checkboxSelected === '1' || checkboxSelected === '2') {
      elementId = `userType-${compareTitles(checkboxSelected)}`;
    }

    this.handleChangeCheckbox(elementId, false);

    this.setState({ filterList });
  };

  handleOnRemoveBrand = (list: any) => {
    delete list.brand;

    if (list.model || list.version) {
      delete list.model;
      delete list.version;
    }

    return list;
  };

  handleOnRemoveModel = (list: any) => {
    delete list.model;

    if (list.version) {
      delete list.version;
    }

    return list;
  };

  handleOnRemoveCity = (list: any) => {
    delete list.city;

    if (list.state || list.fantasyName) {
      delete list.state;
      delete list.fantasyName;
    }

    return list;
  };

  handleOnRemoveUserType = (checkboxSelected: string) => {
    this.handleChangeCheckbox(`userType-${compareTitles(checkboxSelected)}`, false);
  };

  handleOnRemoveMileage = () => {
    this.setState({
      valueKm: { minKm: null, maxKm: null },
    });
  };

  handleOnRemoveBussSeats = () => {
    this.setState({
      valueBussSeat: { minBussSeat: '', maxBussSeat: '' },
    });
  };

  handleOnRemovePrice = (list: any, valuePrice: { minPrice: string; maxPrice: string }) => {
    delete list.price;

    valuePrice = { minPrice: '', maxPrice: '' };

    return { list, valuePrice };
  };

  handleOnRemoveMotorcycle = (list: any, valueMotorcycle: ValueMotorCycle) => {
    delete list.motor;

    valueMotorcycle = { minMotorcycle: '', maxMotorcycle: '' };

    return { list, valueMotorcycle };
  };

  handleOnRemoveSearch = (list: any) => {
    delete list.search;
    this.setState({ clearSearchInputValue: true, searchValue: '' });
    return list;
  };

  handleOnRemoveVehicleCaracteristics = (list: any, key: string, checkboxSelected: string) => {
    this.handleChangeCheckbox(checkboxSelected, false);

    if (_endsWith(checkboxSelected, 'portas') || _endsWith(checkboxSelected, 'marchas')) {
      checkboxSelected = checkboxSelected.replace(/\D/g, '');
    }

    const caracteristics = get(list[key], 'in', []);

    if (caracteristics.length > 1) {
      const checkedCaracteristics = _filter(caracteristics, caracteristic => {
        return caracteristic !== checkboxSelected;
      });

      if (checkedCaracteristics.length) {
        list[key].in = checkedCaracteristics;
      }

      return list;
    }

    delete list[key];

    return list;
  };

  handleOnRemoveOptionals = (list: any, optionalSelected: string) => {
    let optionals = get(list.optionals, 'include', []);

    optionals = _filter(optionals, optional => optional !== optionalSelected);

    if (optionals.length) {
      list.optionals = {
        include: optionals,
      };

      return list;
    }

    delete list.optionals;

    return list;
  };

  handleOnSimpleRemove = (list: any, key: string) => {
    delete list[key];

    return list;
  };

  handleRemoveItemsChips = (
    index: keyof FilterList | string,
    checkboxSelected: string,
    category?: number | string,
    callback?: () => void,
  ) => {
    if (index === 'type') {
      return;
    }

    const vehicleCaracteristicsFilters = [
      'transmission',
      'fuelType',
      'color',
      'ports',
      'marches',
      'bodyStyle',
      'motor',
    ];

    const removeComplexFilters = [
      'transmission',
      'fuelType',
      'color',
      'ports',
      'optionals',
      'marches',
      'bodyStyle',
      'motor',
    ];

    let { valuePrice, filterList, valueMotorcycle } = this.state;

    if (index === 'pesquisar') {
      filterList = this.handleOnRemoveSearch(filterList);
      this.setState({ clearSearchInputValue: true, searchValue: '' });
    }

    if (index === 'brand') {
      filterList = this.handleOnRemoveBrand(filterList);
    }

    if (index === 'model') {
      filterList = this.handleOnRemoveModel(filterList);
    }

    if (index === 'city') {
      filterList = this.handleOnRemoveCity(filterList);
    }

    if (index === 'price') {
      filterList = this.handleOnRemovePrice(filterList, valuePrice).list;
      valuePrice = this.handleOnRemovePrice(filterList, valuePrice).valuePrice;
    }

    if (index === 'motor' && category === MOTORCYCLE_NUMBER) {
      filterList = this.handleOnRemoveMotorcycle(filterList, valueMotorcycle).list;
      valueMotorcycle = this.handleOnRemoveMotorcycle(filterList, valueMotorcycle).valueMotorcycle;
    }

    if (index === 'userType') {
      this.handleOnRemoveUserType(checkboxSelected);
    }

    if (index === 'mileage') {
      this.handleOnRemoveMileage();
    }

    if (index === 'seatsAmount') {
      this.handleOnRemoveBussSeats();
    }

    if (index === 'partType') {
      filterList = this.handleOnRemoveCategoryPart(filterList);
    }

    if (index === 'newPart') {
      this.handleUncheck('newPart', 'false', 'Nova');
      this.handleUncheck('newPart', 'false', 'Usada');
    }

    if (
      _includes(vehicleCaracteristicsFilters, index) ||
      (index === 'motor' && category !== MOTORCYCLE_NUMBER)
    ) {
      filterList = this.handleOnRemoveVehicleCaracteristics(filterList, index, checkboxSelected);
    }

    if (index === 'optionals' || index === 'stateVehicle') {
      filterList = this.handleOnRemoveOptionals(filterList, checkboxSelected);
    }

    if (!_includes(removeComplexFilters, index)) {
      filterList = this.handleOnSimpleRemove(filterList, index);
    }

    const lastItemSelected = [];
    lastItemSelected.push({ [index]: filterList[index as keyof FilterList] });

    this.setState(
      {
        isLoading: true,
        filterList,
        valuePrice,
        valueMotorcycle,
        lastItemSelected,
        lastFilters: null,
      },
      () => {
        if (callback) {
          return callback();
        }

        return this.handleSetFilterList();
      },
    );
  };

  handleOnRemoveCategoryPart = (list: any) => {
    delete list.modelType;
    return list;
  };

  handleClickPartType = (ev: any) => {
    let { filterList } = this.state;
    const { lastItemSelected } = this.state;

    filterList = this.handleSetFilterParts(filterList, 'modelType', ev.value);

    lastItemSelected.push({ [ev.value]: filterList[ev.value] });

    this.setState({ filterList, lastItemSelected }, () => {
      this.handleSetFilterList({}, '');
    });
  };

  handleClickPartCategorie = (ev: any) => {
    let { filterList } = this.state;
    const { lastItemSelected } = this.state;
    const { part_categorie, categories, subCategories, subSubCategories } = ev;
    if (part_categorie) {
      filterList = {
        ...filterList,
        part_categorie,
      };
      lastItemSelected.push({ part_categorie });
      this.setState(
        {
          filterList,
          lastItemSelected,
          partCategories: categories,
          partSubCategories: subCategories,
          partSubSubCategories: subSubCategories,
        },
        () => {
          this.handleSetFilterList({}, '');
        },
      );
    }
  };

  handleClickSearch = (
    category: number,
    inputValue?: string | number,
    isService?: boolean,
    brand?: any,
  ) => {
    const { searchBrand, savedStateWhenAsideOpen, filterList } = this.state;

    filterList.type.eq = category;

    const newBrand = brand || searchBrand;

    const lastItemSelected = [];
    lastItemSelected.push({ search: inputValue });
    const vehicleType = filterList.type.eq;
    if (vehicleType === PARTS_NUMBER) {
      this.handleClickClearAll();
      filterList.type = { eq: category };
      filterList.search = inputValue;
      window.history.replaceState(null, '', '/anuncios/busca/?categoria=pecas');
      return this.setState(
        {
          filterList,
          lastItemSelected,
          typeSearch: vehicleTypeNameByNumber(category),
          responseData: [],
          responseServicesData: [],
          searchValue: inputValue ? inputValue.toString() : '',
          sidebarOpen: true,
          collapseSidebar: true,
        },
        () => {
          handleSearch({
            selectedCategory: category,
            values,
            searchedFromSidebar: true,
          });
          this.handleSetFilterList();
        },
      );
    }

    this.setState({ ...savedStateWhenAsideOpen, lastItemSelected }, () => {
      if (!isService) {
        if (inputValue && typeof inputValue === 'string') {
          handleSearch({
            inputValue,
            selectedCategory: category,
            searchedFromSidebar: true,
          });
        } else {
          handleSearch({
            searchBrand: newBrand,
            selectedCategory: category,
            values,
            searchedFromSidebar: true,
          });
        }
        const userType = filterList.userType && !isService ? filterList.userType.eq : null;
        return this.handleUnCheckAll({ searchFromQueryParams: true, userType });
      }
      this.handleSetFilterList();
    });
  };

  handleChangeSearch = (e: any, makeSearch?: boolean, category?: number) => {
    const searchBrand = formatParamsSearch(e);
    this.setState({ searchBrand });

    if (makeSearch) {
      const categoryToSearch = category || 1;
      this.handleClickSearch(categoryToSearch, '', false, searchBrand);
    }
  };

  handleOnChangeBrandModelVersion = (typeSearch: keyof FilterList, e: string) => {
    let { filterList } = this.state;

    filterList = this.handleSetFilterUnique(filterList, { key: typeSearch, eq: e });

    this.handleSaveLastFilters(typeSearch);

    const lastItemSelected = [];
    lastItemSelected.push({ [typeSearch]: filterList[typeSearch] });

    this.setState({ filterList }, () => {
      this.handleSetFilterList();
    });
  };

  handleChangeYearKm = () => {
    let { filterList } = this.state;

    const lastItemSelected = [];

    if (filterList.modelYear) {
      const { between } = filterList.modelYear;
      const { start, end } = between as unknown as Range;

      filterList = this.handleSetFilterBetween(filterList, { key: 'modelYear', start, end });

      lastItemSelected.push({ modelYear: filterList.modelYear });
    }

    this.setState({ filterList, lastItemSelected }, () => {
      this.handleSetFilterList();
    });
  };

  handleGetYearValues = (type: string, e: any) => {
    const { filterList } = this.state;
    const queryParams = this.queryParams();

    if (type === 'initialYear') {
      const finalYear = get(queryParams, 'ano_modelo', 0);

      filterList.modelYear = {
        between: {
          start: e.value,
          end: finalYear,
        },
      };
    }

    if (type === 'finalYear') {
      const initialYear = get(filterList, 'modelYear.between.start', 0);

      filterList.modelYear = {
        between: {
          start: initialYear,
          end: e.value,
        },
      };
    }

    const filterListStart = get(filterList, 'modelYear.between.start', 0);
    const filterListEnd = get(filterList, 'modelYear.between.end', 0);

    if (!filterListStart || !filterListEnd) {
      return;
    }

    this.setState({ filterList }, () => this.handleChangeYearKm());
  };

  setValuesKM = (e: any) => {
    const { filterList, valueKm } = this.state;

    filterList.mileage = { between: { start: e[0], end: e[1] } };

    valueKm.minKm = e[0];
    valueKm.maxKm = e[1];

    const lastItemSelected = [];
    lastItemSelected.push({ mileage: filterList.mileage });

    if (valueKm.maxKm !== '0') {
      this.setState({ filterList, isLoading: true, valueKm, lastItemSelected }, () => {
        this.handleSetFilterList();
      });
    }
  };

  setValuesPrice = debounce((e: any) => {
    const { filterList, valuePrice } = this.state;

    const start = removeMaskMoney(e[0]);
    const end = removeMaskMoney(e[1]);

    filterList.price = { between: { start, end } };

    valuePrice.minPrice = e[0];
    valuePrice.maxPrice = e[1];

    const lastItemSelected = [];
    lastItemSelected.push({ price: filterList.price });

    this.setState({ filterList, isLoading: true, valuePrice, lastItemSelected }, () => {
      this.handleSetFilterList();
    });
  }, 300);

  setValuesMotorcyle = debounce((e: any) => {
    const { filterList, valueMotorcycle } = this.state;

    filterList.motor = { between: { start: e[0], end: e[1] } };

    valueMotorcycle.minMotorcycle = e[0];
    valueMotorcycle.maxMotorcycle = e[1];

    const lastItemSelected = [];
    lastItemSelected.push({ motor: filterList.motor });

    this.setState({ filterList, isLoading: true, valueMotorcycle, lastItemSelected }, () => {
      this.handleSetFilterList();
    });
  }, 300);

  setValueBussSeat = debounce((e: any) => {
    const { filterList, valueBussSeat } = this.state;

    filterList.seatsAmount = { between: { start: e[0], end: e[1] || '99' } };

    valueBussSeat.minBussSeat = e[0];
    valueBussSeat.maxBussSeat = e[1];

    const lastItemSelected = [];
    lastItemSelected.push({ seatsAmount: filterList.seatsAmount });

    this.setState({ filterList, isLoading: true, valueBussSeat, lastItemSelected }, () => {
      this.handleSetFilterList();
    });
  }, 300);

  handleStoreByCity = (type: keyof FilterList, e: any) => {
    let { filterList } = this.state;

    const lastItemSelected = [];

    if (type === 'city') {
      const city = e.split('-');

      filterList = this.handleSetFilterUnique(filterList, { key: 'city', eq: city[0] });
      filterList = this.handleSetFilterUnique(filterList, { key: 'state', eq: city[1] });

      lastItemSelected.push({ city: filterList.city }, { state: filterList.state });
    } else {
      filterList = this.handleSetFilterUnique(filterList, { key: type, eq: e.label });

      lastItemSelected.push({ [type]: filterList[type] });
    }

    this.setState({ filterList, lastItemSelected }, () => {
      this.handleSetFilterList();
    });
  };

  handleChangeOrderBy = (orderSelected: any) => {
    const order = isEmpty(orderSelected.value) ? null : orderSelected.value;

    this.setState({ isLoading: true, order, page: 0 }, () => this.handleSetFilterList());
  };

  handleSearchAgain = () => {
    const { lastItemSelected, filterList } = this.state;

    if (!lastItemSelected || (lastItemSelected && !lastItemSelected.length)) {
      if (filterList.search) {
        delete filterList.search;
      }
      return this.setState({ filterList }, () => {
        this.handleSetFilterList();
      });
    }

    const key = Object.keys(lastItemSelected[0])[0] as keyof FilterList;

    let checkboxSelected = '';

    const defaultFilters = [
      'type',
      'brand',
      'model',
      'version',
      'modelYear',
      'mileage',
      'price',
      'city',
      'state',
      'fantasyName',
      'search',
    ];

    if (!_includes(defaultFilters, key)) {
      checkboxSelected = compareTitles(filterList[key]?.eq) || filterList[key]?.include?.[0];
      this.handleChangeCheckbox(checkboxSelected, false);
    }

    this.handleRemoveItemsChips(key, checkboxSelected, filterList.type.eq, () => {
      this.setState({ filterList }, () => {
        this.handleSetFilterList();
      });
    });
  };

  handleClickClearAll = () => {
    const { savedStateWhenAsideOpen } = this.state;

    const queryParams = this.queryParams();

    const filterKeys = Object.keys(queryParams);

    if (_includes(filterKeys, 'categoria') && filterKeys.length === 1) {
      this.setState({
        sidebarOpen: false,
        collapseSidebar: false,
      });
      return;
    }

    this.setState(
      {
        sidebarOpen: false,
        collapseSidebar: false,
        ...savedStateWhenAsideOpen,
      },
      () => {
        this.handleSetFilterList();

        unCheckedAllCheckBox('_hasChecked');
      },
    );
  };

  handleGetBrandName = () => {
    const { filterList, lastBrands, filters } = this.state;

    if (!filterList.model && !filterList.version) {
      return lastBrands;
    }

    return filters && filters.brandName;
  };

  handleGetModelName = () => {
    const { filterList, lastModels, filters } = this.state;

    if (!filterList.version) {
      return lastModels;
    }

    return filters && filters.modelName;
  };

  handleChangeLocality = () => {
    this.setState({ page: 0 });
  };

  renderApplyFiltersButton() {
    const { sidebarOpen } = this.state;

    const buttonClass = cx(
      'w-100 ml0 flex flex-column items-center dn-ns fixed z-2 bottom-0 bg-white',
      styles.submitFilters,
    );

    if (sidebarOpen) {
      return (
        <div className={buttonClass}>
          <Button classButton={styles.submitFilterButton} onClick={this.handleFilterClick}>
            FILTRAR
          </Button>
          <button
            className="red underline f14 fw6 bg-transparent bn db di-ns center flex justify-center pb32"
            onClick={this.handleClickClearAll}
          >
            CANCELAR
          </button>
        </div>
      );
    }
  }

  renderSearchWithChips() {
    const {
      filterList,
      isLoading,
      order,
      amountFound,
      searchValue,
      partCategories,
      partSubCategories,
      partSubSubCategories,
    } = this.state;
    const { city, state } = filterList;
    const newCity = city && state ? `${city.city}-${state.state}` : 'Uberlândia-MG';

    if (isLoading) {
      return null;
    }

    return (
      <section className="pb16-l pl24-l">
        <SearchWithChips
          items={filterList as unknown as string[]}
          numberOfProducts={amountFound}
          location={newCity}
          onRemove={this.handleRemoveItemsChips}
          onRemoveChekboxChecked={this.handleUnCheckAll}
          order={order}
          searchValue={searchValue}
          partCategories={partCategories}
          partSubCategories={partSubCategories}
          partSubSubCategories={partSubSubCategories}
        />
      </section>
    );
  }

  renderResponseNoData() {
    const { responseNoData, isLoading } = this.state;

    if (!responseNoData || isLoading) {
      return null;
    }

    return <EmptyState searchAgain={this.handleSearchAgain} />;
  }

  renderButtonLoadMore(vehicleNumber: string | number) {
    const vehicleType = compareTitles(vehicleTypeNameByNumber(vehicleNumber));
    const text = `Carregar mais ${vehicleType}`;

    const { responseNoData, showButtonLoadMore, isLoading, loadingBtn } = this.state;

    if (responseNoData || !showButtonLoadMore || isLoading) {
      return null;
    }

    return (
      <BtnMoreCards
        handleRequestNextPage={this.handleRequestNextPage}
        loading={loadingBtn}
        text={text}
      />
    );
  }

  renderCards = (cardsData: any) => {
    const { isLoading, filterList } = this.state;

    if (isLoading) {
      return <LoaderCard />;
    }

    return map(
      cardsData,
      (offer: { id: string; mileage: number; description: string; price: number }, idx) => {
        const { sidebarOpen } = this.state;
        const { id, description } = offer;

        return (
          <Cards
            key={`${id}--${description}-${idx}`}
            offer={offer}
            sidebarOpen={sidebarOpen}
            vehicleType={filterList.type.eq || ''}
          />
        );
      },
    );
  };

  renderContentSidebar = () => {
    const {
      searchBrand,
      isLoading,
      sidebarOpen,
      typeSearch,
      clearSearchInputValue,
      filterList,
      filters,
      valueKm,
      valuePrice,
      countOld,
      countNew,
      valueBussSeat,
      valueMotorcycle,
    } = this.state;

    const styleAside = cx(
      'w-100 h-100 overflow-y-auto pt32 pt40-ns ph24 ph0-m ph0-l pb12-l ph32-l',
      styles.aside,
    );

    const selectedCategory = vehicleTypeNumberByName(typeSearch);

    const currentYear = new Date().getFullYear() + 1;

    const initialValuesSearch = {
      initialYear: 1957,
      finalYear: currentYear,
      price: {},
    };

    return (
      <aside className={styleAside} id="#sidebar-search">
        <ContentSidebar
          filterList={filterList}
          filters={filters}
          valueKm={valueKm}
          valuePrice={valuePrice}
          countOld={countOld}
          countNew={countNew}
          selectedCategory={selectedCategory}
          brandName={this.handleGetBrandName()}
          modelName={this.handleGetModelName()}
          handleOnChangeBrandModelVersion={this.handleOnChangeBrandModelVersion}
          handleGetYearValues={this.handleGetYearValues}
          ONLYTHESESDIVIDERHASMB4={this.ONLYTHESESDIVIDERHASMB4}
          setValuesKM={this.setValuesKM}
          valueBussSeat={valueBussSeat}
          setValueBussSeat={this.setValueBussSeat}
          setValuesPrice={this.setValuesPrice}
          valueMotorcycle={valueMotorcycle}
          setValuesMotorcyle={this.setValuesMotorcyle}
          handleStoreByCity={this.handleStoreByCity}
          handleCheckboxFilters={this.handleCheckboxFilters}
          handleFilterClick={this.handleFilterClick}
          sidebarOpen={sidebarOpen}
          handleClickSearch={this.handleClickSearch}
          handleClickPartType={this.handleClickPartType}
          handleClickPartCategorie={this.handleClickPartCategorie}
          handleChangeSearch={this.handleChangeSearch}
          searchBrand={searchBrand}
          isLoading={isLoading}
          initialValuesSearch={initialValuesSearch}
          currentYear={currentYear}
          handleGetParamsFromUrlAndSearch={this.handleGetParamsFromUrlAndSearch}
          clearSearchInputValue={clearSearchInputValue}
          services={get(filters, 'services', [])}
          changeLocality={this.handleChangeLocality}
          onSetSidebarOpen={this.onSetSidebarOpen}
        />
      </aside>
    );
  };

  renderSearchContentDefault = () => {
    const { sidebarOpen, responseData, filterList, responseServicesData, isLoading } = this.state;

    const styleWrapperContentSidebar = cx(
      'w-100 h-auto bg-wild-sand overflow-auto',
      { 'pl24-l': sidebarOpen },
      styles.contentSidebar,
    );

    const styleWrapperSectionCards = cx(
      'relative pt24-ns pt24-m pt0-l pb0 pb8-ns pb0-m pb0-l pl24-l pr24-l',
      {
        'flex justify-center': isEmpty(responseData),
      },
    );

    const vehicleNumber = get(filterList, 'type.eq', 1);

    if (vehicleNumber === SERVICES_NUMBER) {
      const stores = servicesStoreAdapter(responseServicesData);

      return (
        <>
          <FilterButtons
            onSetSidebarOpen={this.onSetSidebarOpen}
            sidebarOpen={sidebarOpen}
            handleFilterClick={this.handleFilterClick}
          />
          <section
            className={styleWrapperContentSidebar}
            tabIndex={1}
            id="cards"
            ref={this.sectionCardRef}
          >
            <Container>
              <SectionBanner position="resultSearch" className="db mb0 pt40" />
              <Grid className="flex mr24-l">
                <GridCell width={[1, 9 / 12]}>{this.renderSearchWithChips()}</GridCell>
              </Grid>
              <Grid
                width={1}
                className="flex flex-wrap justify-center justify-start-ns justify-start-m justify-start-l"
              >
                <Services
                  stores={stores}
                  isLoading={isLoading}
                  messageEmptyState="Não encontramos nenhuma loja"
                  sidebarOpen={sidebarOpen}
                />
              </Grid>
            </Container>
            {this.renderButtonLoadMore(vehicleNumber)}
          </section>
        </>
      );
    }

    return (
      <>
        <FilterButtons
          onSetSidebarOpen={this.onSetSidebarOpen}
          sidebarOpen={sidebarOpen}
          handleFilterClick={this.handleFilterClick}
        />
        <section
          className={styleWrapperContentSidebar}
          tabIndex={1}
          id="cards"
          ref={this.sectionCardRef}
        >
          <Container>
            <SectionBanner position="resultSearch" className="db mb0 pt40" />
            <Grid className="flex mr24-l">
              <GridCell width={[1, 9 / 12]}>{this.renderSearchWithChips()}</GridCell>
              <GridCell width={[1, 3 / 12]}>
                <OrderBy
                  onChange={this.handleChangeOrderBy}
                  vehicleType={filterList.type.eq || ''}
                />
              </GridCell>
            </Grid>
            <Grid width={1} className={styleWrapperSectionCards}>
              {this.renderCards(responseData.length ? responseData : responseServicesData)}
              {this.renderResponseNoData()}
            </Grid>
          </Container>
          {this.renderButtonLoadMore(vehicleNumber)}
        </section>
      </>
    );
  };

  render() {
    const { isLoading, sidebarOpen, collapseSidebar, loaderFull } = this.state;
    const queryParams = this.queryParams();
    const isService = get(queryParams, 'categoria', '') === 'servicos';

    if (loaderFull && !isService) {
      return <LoaderFullPage />;
    }

    const sideBarClassName = cx(
      'bg-white w-100 w-auto-ns w-auto-m w-auto-l',
      styles.sideBarClassName,
    );

    const docked = dockedMQL.matches ? collapseSidebar : false;

    return (
      <>
        <Media query="(min-width: 768px)">
          <Modal
            className="fixed top-0 right-0 bottom-0 left-0 o-40"
            isOpen={isLoading}
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
          />
        </Media>
        <section className="db db-ns dn-m dn-l">
          <Top />
        </section>
        <section className="dn db-m db-l">
          <Menu menuHasTwoColors={false} />
        </section>
        <Sidebar
          sidebar={this.renderContentSidebar()}
          sidebarId="sidebar"
          rootClassName={cx(styles.sidebar, { [styles.marginBottom90]: sidebarOpen })}
          sidebarClassName={sideBarClassName}
          open={sidebarOpen}
          docked={docked}
          onSetOpen={this.onSetSidebarOpen}
          transitions
          shadow
        >
          {this.renderSearchContentDefault()}
          <Footer />
        </Sidebar>
        {this.renderApplyFiltersButton()}
      </>
    );
  }
}
