import { find, values, flattenDeep, filter, head, map } from 'lodash';
import groupBy from 'lodash/groupBy';

const extractOptionalsByCategory = (group: any) => {
  if (!group) {
    return null;
  }

  const itemsByCategory = map(group, item => {
    return {
      textLabel: item && item.name,
      qtd: item && item.qtd,
      value: item && item.name,
    };
  });
  return { optionals: itemsByCategory };
};

const formattedListOptionalsData = (optionalGroup: any) => {
  const formattedListOptionals = map(optionalGroup, (group, index) => {
    return {
      title: index,
      optionals: extractOptionalsByCategory(group),
    };
  });

  return formattedListOptionals;
};

const groupByCategory = (objectEquals: any) => {
  const objectEqualsNotUndefined = filter(objectEquals, item => item !== undefined);
  const optionalItemsByGroup = groupBy(objectEqualsNotUndefined, 'category');

  return optionalItemsByGroup;
};

export const normalizeOptionals = (optionals: any, dataOptionals: any) => {
  if (!dataOptionals) {
    return null;
  }
  const isolateObjectKey = map(dataOptionals, opt => {
    const key = head(Object.keys(opt)) || '';
    return key.replace(/\"/g, '');
  });

  const objectEqualsFormatted = map(isolateObjectKey, item => {
    const filterEqual = filter(optionals, { name: item });

    const formatEqual = map(filterEqual, (itemEqual: any) => {
      const itemFound = find(dataOptionals, opt => {
        const key = head(Object.keys(opt)) || '';
        if (key.replace(/\"/g, '') === itemEqual.name) {
          return opt;
        }
      });

      const qtd = values(itemFound)[0] || '';

      return {
        category: itemEqual.category,
        name: itemEqual.name,
        value: itemEqual.name,
        featured: itemEqual.featured,
        qtd,
      };
    });
    return formatEqual;
  });

  const flattObjectEquals = flattenDeep(objectEqualsFormatted);

  const optionalItemsByGroup = groupByCategory(flattObjectEquals);
  const formatted = formattedListOptionalsData(optionalItemsByGroup);
  return formatted;
};
