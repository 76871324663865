import Axios from 'axios';

export const findPartCategories = async () => {
  const categories = [];
  try {
    const { data } = await Axios.get(`${process.env.BASE_API_DEV}/partCategories`);
    data.forEach(item => {
      categories.push({
        value: item.id,
        label: item.name,
        parent: null,
        subParent: null,
      });

      item?.children.forEach(child => {
        categories.push({
          value: child.id,
          label: `${child.name}`,
          parent: item.id,
          subParent: null,
        });

        child?.children.forEach(secondChild => {
          categories.push({
            value: secondChild.id,
            label: `${secondChild.name}`,
            parent: item.id,
            subParent: child.id,
          });
        });
      });
    });
  } catch (e) {
    console.log(e);
  }
  return categories;
};
