import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import cx from 'classnames';
import map from 'lodash/map';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import style from './Components.module.css';

export interface IOffer {
  id: string;
  url: string;
}

interface Props {
  offers: IOffer[];
}

export const Offers = ({ offers }: Props) => {
  const offerStyle = cx('ba b--alto br3 pointer mh8-l ', style.OffersSizing);
  const OfferCards = map(offers, (offer: IOffer) => {
    const { id, url } = offer;
    return <LazyLoadImage key={id} src={url} className={offerStyle} />;
  });
  return <li className="pt12 pt24-l pb8 pb24-l">{OfferCards}</li>;
};
