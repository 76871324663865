import React, { useState } from 'react';
import cx from 'classnames';
import map from 'lodash/map';
import { Collapse } from 'react-collapse';
import { compareTitles } from '../../utils/comparationFilters';
import { Checkbox } from '../Checkbox';
import { Icon } from '../Icons';
import styles from './style.module.css';

interface FilterOptions {
  value?: any;
  qtd: number;
  textLabel: string;
}
interface Props {
  title: string | number;
  filterOptions: FilterOptions[] | any;
  onChange: (event: any) => void;
  sectionClassname?: any;
  name?: string;
  id?: string;
  checked?: boolean;
}

export const CheckBoxFilter = ({
  title,
  filterOptions,
  onChange,
  sectionClassname,
  name,
  checked,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const styleCheckboxes = cx(styles.aroundCheckboxes);
  const iconClass = cx('ml8', {
    'rotate-270': isOpen,
    'rotate-90': !isOpen,
  });
  const buttonClass = cx(
    'abbey pt0 pl0 fw7 f14 lh-copy bn bg-transparent flex items-center pointer',
    styles.buttonClass,
  );

  const mapFilterOptions = (Options: FilterOptions[]) => {
    return map(Options, option => {
      const { textLabel, qtd, value } = option;
      let normalizeNameId = value && value.toString().replace(/\s/g, '');

      if (title === 'ports') {
        normalizeNameId = `${normalizeNameId} portas`;
      }

      if (title === 'marches') {
        normalizeNameId = `${normalizeNameId} marchas`;
      }

      if (textLabel === '0 KM') {
        return null;
      }

      if (name === 'userType') {
        normalizeNameId = `userType-${normalizeNameId}`;
      }

      if (name === 'newPart') {
        normalizeNameId = textLabel;
      }

      return (
        <div key={textLabel} className={styleCheckboxes}>
          <Checkbox
            id={normalizeNameId}
            name={name}
            textLabel={textLabel}
            onChange={onChange}
            checked={checked}
            value={value}
            qtd={qtd}
          />
        </div>
      );
    });
  };

  const controlSection = () => {
    setIsOpen(!isOpen);
  };

  const renderCollapseButton = () => {
    if (filterOptions.length < 6) {
      return;
    }

    return (
      <button className={buttonClass} onClick={controlSection}>
        <span>{isOpen ? 'Menos' : 'Mais'}</span>
        <div className="flex items-center">
          <Icon className={iconClass} name="SvgIconArrowDownBold" width={12} height={12} />
        </div>
      </button>
    );
  };

  const renderFixedItems = () => {
    const fixedItems = filterOptions.slice(0, 5);
    return mapFilterOptions(fixedItems);
  };

  const renderCollapseItems = () => {
    const collapseItems = filterOptions.slice(5);
    return mapFilterOptions(collapseItems);
  };

  return (
    <section className={cx(sectionClassname, 'mb48')}>
      <h3 className="abbey b normal ttu f16 lh-title"> {compareTitles(title)} </h3>
      <div>{renderFixedItems()}</div>
      <Collapse
        isOpened={isOpen}
        hasNestedCollapse
        springConfig={{ stiffness: 100, damping: 20 }}
      >
        {renderCollapseItems()}
      </Collapse>
      {renderCollapseButton()}
    </section>
  );
};
