import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import { defaultConfigToApply } from '../../../../utils/randomPhoto';
import { NameInitials } from '../../../NameInitials';
import styles from './CardHeader.module.css';

interface Props {
  cover?: string;
  logo?: string;
  name: string;
  isMyAds?: boolean;
  isPhisicalPerson?: boolean;
  urlFrontagePhoto: string;
  urlCompanyLogo: string;
}

export const CardHeader: FunctionComponent<Props> = ({
  name,
  isMyAds,
  urlCompanyLogo,
  urlFrontagePhoto,
}) => {
  const { bgImage, bgColor } = defaultConfigToApply;

  const cls = cx('br-100 flex justify-center w-100 h-100 items-center f44 abbey fw1');

  const coverImageStyle = cx('w-100 cover bg-center flex items-end relative justify-center', {
    'br10-ns br--top-ns': isMyAds,
    [styles.coverMyAdsSizing]: isMyAds,
    'br10 br--top': !isMyAds,
    [styles.coverPictureSizing]: !isMyAds,
  });

  const logoCls = cx(styles.logo, 'absolute br-100 ba b--gray ma0 pa0 overflow-hidden', {
    [styles.logoMyAds]: isMyAds,
  });
  const style = { backgroundColor: bgColor };

  const renderAvatar = () => {
    if (!urlCompanyLogo) {
      return (
        <div className={cls} style={style}>
          <NameInitials name={name} />
        </div>
      );
    }
    return <img src={`${process.env.FTP_IMAGENS}${urlCompanyLogo}`} alt={name} />;
  };

  const background = urlFrontagePhoto ? `${process.env.FTP_IMAGENS}${urlFrontagePhoto}` : bgImage;

  return (
    <header className={coverImageStyle} style={{ backgroundImage: `url(${background})` }}>
      <figure className={logoCls}>{renderAvatar()}</figure>
    </header>
  );
};
