import React from 'react';
import _includes from 'lodash/includes';
import map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import { CheckBoxFilter } from '../../../components/CheckBoxFilter';
import {
  MOTORCYCLE_NUMBER,
  BUSS_NUMBER,
  CARGO_VEHICLE_NUMBER,
  NAUTICAL_VEHICLE_NUMBER,
  AGRICULTURAL_VEHICLE_NUMBER,
  CAR_NUMBER,
  PARTS_NUMBER,
  compareTitles,
} from '../../../utils/comparationFilters';
import { FilterList } from '../types';

interface Props {
  onChange: (event: {
    currentTarget: {
      value: string | any;
      name: keyof FilterList;
      checked: boolean;
      type: string;
      id: string;
    };
  }) => void;
  checked?: boolean;
  filters: any;
  typeSearch: number | string;
}

export const SectionOptionalsCheckbox = ({ onChange, checked, filters, typeSearch }: Props) => {
  if (!filters) {
    return null;
  }

  const formattedFilters = map(filters, (filter: any, key: any) => {
    const listItems = map(filter, (item, keyItem: any) => {
      if (typeof keyItem === 'string' && keyItem.search('.00') !== -1) {
        keyItem = parseInt(keyItem, 10).toString();
      }

      const keyName = Object.keys(item)[0];

      return {
        textLabel: keyName,
        qtd: item[keyName],
        value: keyName,
      };
    });

    const carFilters = ['fuelType', 'color', 'ports'];
    const motorCycleFilters = ['matchType', 'coolingType', 'marches', 'fuelType', 'color'];
    const busFilters = ['busType', 'ports', 'fuelType', 'bodyStyle', 'color'];
    const cargoFilters = [
      'axles',
      'loadCapacity',
      'cargoVehicleType',
      'bodyStyle',
      'fuelType',
      'motor',
      'color',
    ];
    const nauticalFilters = ['color', 'fuelType', 'bodyStyle', 'loadCapacity'];
    const agriculturalFilters = ['color', 'fuelType', 'bodyStyle'];
    const partsFilters = ['partType'];

    if (typeSearch === CAR_NUMBER && _includes(carFilters, key)) {
      return { [key]: listItems };
    }

    if (typeSearch === MOTORCYCLE_NUMBER && _includes(motorCycleFilters, key)) {
      return { [key]: listItems };
    }

    if (typeSearch === BUSS_NUMBER && _includes(busFilters, key)) {
      return { [key]: listItems };
    }

    if (typeSearch === CARGO_VEHICLE_NUMBER && _includes(cargoFilters, key)) {
      return { [key]: listItems };
    }

    if (typeSearch === NAUTICAL_VEHICLE_NUMBER && _includes(nauticalFilters, key)) {
      return { [key]: listItems };
    }

    if (typeSearch === AGRICULTURAL_VEHICLE_NUMBER && _includes(agriculturalFilters, key)) {
      return { [key]: listItems };
    }

    if (typeSearch === PARTS_NUMBER && _includes(partsFilters, key)) {
      return { [key]: listItems };
    }
  });

  const renderCheckboxfilter = map(formattedFilters, (item, key) => {
    return map(item, (itemList, keyList) => {
      const ordenedAscItemList = _orderBy(itemList, ['textLabel'], ['asc']);

      return (
        <div key={keyList + key}>
          <CheckBoxFilter
            filterOptions={ordenedAscItemList}
            title={compareTitles(keyList)}
            name={keyList}
            onChange={onChange}
            checked={checked}
          />
        </div>
      );
    });
  });

  return <>{renderCheckboxfilter}</>;
};
