import React, { useState, ChangeEvent, useEffect } from 'react';
import inRange from 'lodash/inRange';
import isEmpty from 'lodash/isEmpty';
import { FilterWrapper } from '../../../../components/FilterWrapper';
import { TextInput } from '../../../../components/TextInput';
import { onlyNumbers, money, formatKm } from '../../../../utils/mask';

interface Props {
  title: string;
  isCurrency?: boolean;
  isKilometers?: boolean;
  max: any;
  min: any;
  setValue: (e: [number | string, number | string]) => void;
  range?: [number, number];
  maxLength?: number;
}
export const FilterBetween = ({
  title,
  min,
  max,
  setValue,
  isCurrency,
  isKilometers,
  range,
  maxLength,
}: Props) => {
  const [minValue, setMinValue] = useState<any>(min);
  const [maxValue, setMaxValue] = useState<any>(max);
  const [minValueError, setMinValueError] = useState<string>('');
  const [maxValueError, setMaxValueError] = useState<string>('');

  useEffect(() => {
    setMinValue(min);
    setMaxValue(max);
  }, [min, max]);

  const getMinValue = (e: ChangeEvent<HTMLInputElement>): void => {
    setMinValue(e.target.value);
  };
  const getMaxValue = (e: ChangeEvent<HTMLInputElement>) => {
    setMaxValue(e.target.value);
  };

  const ruleToIgnoreValidation = () => {
    if (!isEmpty(minValue) && !isEmpty(maxValue)) {
      return true;
    }

    return false;
  };

  const getNakedNumber = (value: any) => {
    return value.replace(/\D/g, '') * 1;
  };

  const isThisValueValid = (valueType: string) => {
    if (valueType === 'minValue') {
      return getNakedNumber(minValue) > getNakedNumber(maxValue);
    } else {
      return getNakedNumber(maxValue) < getNakedNumber(minValue);
    }
  };

  const clearErrors = () => {
    setMinValueError('');
    setMaxValueError('');
  };

  const replaceValue = (value: any) => {
    let defaultValue = '0';

    if (isCurrency) {
      defaultValue = money('000');
    }

    if (!!range) {
      if (!isEmpty(minValue)) {
        defaultValue = '';
      }

      if (!isEmpty(maxValue)) {
        defaultValue = range[0] + '';
      }
    }

    return isEmpty(value) ? defaultValue : value;
  };

  const hasToSearch = () => {
    const nakedMaxValue = getNakedNumber(replaceValue(maxValue));

    return nakedMaxValue > 0;
  };

  const callBackSetValue = () => {
    return setValue([replaceValue(minValue), replaceValue(maxValue)]);
  };

  const getOnblurMinValue = () => {
    clearErrors();
    if (ruleToIgnoreValidation()) {
      if (isThisValueValid('minValue')) {
        return setMinValueError('Este valor não pode ser maior que ' + maxValue);
      }
    }

    if (!!range && !isEmpty(minValue)) {
      if (!inRange(getNakedNumber(minValue), range[0], range[1] + 1)) {
        return setMinValueError('Este valor não pode ser menor que ' + range[0]);
      }
    }

    if (!!range && !isEmpty(maxValue)) {
      if (!inRange(getNakedNumber(maxValue), range[0], range[1] + 1)) {
        return setMaxValueError('Este valor não pode ser maior que ' + range[1]);
      }
    }

    if (hasToSearch()) {
      callBackSetValue();
    }
  };

  const getOnblurMaxValue = () => {
    clearErrors();
    if (ruleToIgnoreValidation()) {
      if (isThisValueValid('maxValue')) {
        return setMaxValueError('Este valor não pode ser menor que ' + minValue);
      }
    }

    if (!!range && !isEmpty(maxValue)) {
      if (!inRange(getNakedNumber(maxValue), range[0], range[1] + 1)) {
        return setMaxValueError('Este valor não pode ser maior que ' + range[1]);
      }
    }

    if (!!range && !isEmpty(minValue)) {
      if (!inRange(getNakedNumber(minValue), range[0], range[1] + 1)) {
        return setMinValueError('Este valor não pode ser menor que ' + range[0]);
      }
    }

    if (hasToSearch()) {
      callBackSetValue();
    }
  };

  const maskedValue = () => {
    if (isCurrency) {
      return money;
    }

    if (isKilometers) {
      return formatKm;
    }

    return onlyNumbers;
  };

  return (
    <FilterWrapper title={title}>
      <div className="flex mt8">
        <div className="mr12">
          <TextInput
            id={`${title}${String(min)}`}
            labeltext="De"
            onChange={getMinValue}
            onBlur={getOnblurMinValue}
            value={minValue}
            mask={maskedValue()}
            errorMessage={minValueError}
            maxLength={maxLength}
          />
        </div>
        <div>
          <TextInput
            id={`${title}${String(max)}`}
            labeltext="Até"
            onBlur={getOnblurMaxValue}
            onChange={getMaxValue}
            value={maxValue}
            mask={maskedValue()}
            errorMessage={maxValueError}
            maxLength={maxLength}
          />
        </div>
      </div>
    </FilterWrapper>
  );
};
