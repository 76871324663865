import React from 'react';
import cx from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
import { remove, clone, orderBy } from 'lodash';
import map from 'lodash/map';
import { CheckBoxFilter } from '../../../../components/CheckBoxFilter';
import styles from '../../style.module.css';
import { normalizeOptionals } from './utils/formattOptionals';

interface Props {
  data?: any;
  dataOptionals: any;
  handleChange: any;
  checked?: boolean;
  typeSearch: number | string;
}
const RenderOptionalsFilter = ({
  handleChange,
  checked,
  data,
  dataOptionals,
  typeSearch,
}: Props) => {
  const DIVIDER = <div className={cx('mt32 bt b--boulder mb32 o-30', styles.divider)} />;

  const dataClone = clone(data);
  const dataUpdated = remove(dataClone, (item: any) => item.vehicle_type === typeSearch);

  let newOptionals = normalizeOptionals(dataUpdated, dataOptionals);
  newOptionals = orderBy(newOptionals, ['title'], ['asc']);

  const renderCheckboxfilter = map(newOptionals, (item, key) => {
    const { title, optionals } = item;
    return map(optionals, (itemList, keyList) => {
      return (
        <div key={keyList + key}>
          <CheckBoxFilter
            filterOptions={itemList}
            title={title}
            name={keyList}
            onChange={handleChange}
            checked={checked}
          />
          {key > 3 && key < 8 && DIVIDER}
        </div>
      );
    });
  });

  return <div>{renderCheckboxfilter}</div>;
};

export const OptionalsFilter = ({ typeSearch, ...props }: Props) => {
  const optionalsData = graphql`
    query {
      allDirectusOptional(filter: { status: { eq: "published" } }) {
        nodes {
          id
          name
          category
          featured
          vehicle_type
        }
      }
    }
  `;

  const renderOptionalsFilter = (data: any) => {
    const {
      allDirectusOptional: { nodes },
    } = data;

    return <RenderOptionalsFilter typeSearch={typeSearch} data={nodes} {...props} />;
  };
  return <StaticQuery query={optionalsData} render={renderOptionalsFilter} />;
};
