import flatten from 'lodash/flatten';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import map from 'lodash/map';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import toPairs from 'lodash/toPairs';
import union from 'lodash/union';
import { onRemoveSpecificItemInArray } from '../../../utils/functions';
import { FilterList } from '../types';

const formattMileage = (value: any, arr: any[]) => {
  const refineArrayMileage = isArray(arr)
    ? map(arr, valueKm => (isNumber(valueKm) ? valueKm : valueKm.split('-')))
    : value;

  if (isArray(arr)) {
    const flattenMileageData = flatten(refineArrayMileage);
    const normalizeArrayMileage = map(flattenMileageData, item => parseInt(item as string, 10));
    const orderAscMileage = sortBy(normalizeArrayMileage);

    return orderAscMileage;
  } else {
    const uniqueItem = flatten(refineArrayMileage.split('-'));
    return uniqueItem;
  }
};

const onRemoveValueOdometer = (arr: any[], value: any) => {
  const dataArray = value.split('-');
  const normalizeArray = map(dataArray, item => parseInt(item, 10));
  remove(arr, item => item === normalizeArray[0] || item === normalizeArray[1] || item === value);
  return arr;
};

export const setFormattFilters = (value: any, keyName: string, filterList: any) => {
  const operator = !filterList[keyName] ? 'eq' : 'in';

  const newValue: any = toPairs(filterList[keyName])[0];
  switch (keyName) {
    case 'mileage':
      const accumulator = filterList[keyName] ? [...filterList[keyName].mileage, value] : value;
      const mileageAttributes = formattMileage(value, accumulator);

      return { operator: 'between', mileage: mileageAttributes };

    case 'optionals':
      if (newValue[1].include) {
        return {
          include: filterList[keyName] ? union([...newValue[1].include[0], value]) : [value],
        };
      }

      return {
        include: filterList[keyName] ? union([...newValue[1], value]) : [value],
      };
    default:
      return {
        [operator]: filterList[keyName] ? union([...newValue[1], value]) : value,
      };
  }
};

export const deletSpecificFilters = (value: any, keyName: keyof FilterList, filterList: any) => {
  const newValue: any = toPairs(filterList[keyName])[0];

  if (newValue) {
    switch (keyName) {
      case 'fuelType':
        const onRemoveFuelType = onRemoveSpecificItemInArray(union([...newValue[1], value]), value);
        const fuelType = onRemoveFuelType.length === 1 ? onRemoveFuelType[0] : onRemoveFuelType;
        if (onRemoveFuelType.length === 0) {
          return [];
        }
        const operator = onRemoveFuelType.length === 1 ? 'eq' : 'in';
        return {
          [operator]: filterList[keyName] ? fuelType : value,
        };
      case 'bodyStyle':
        const onRemovebodyStyle = onRemoveSpecificItemInArray(
          union([...newValue[1], value]),
          value,
        );
        const bodyStyle = onRemovebodyStyle.length === 1 ? onRemovebodyStyle[0] : onRemovebodyStyle;
        if (onRemovebodyStyle.length === 0) {
          return [];
        }
        const operatorB = onRemovebodyStyle.length === 1 ? 'eq' : 'in';
        return {
          [operatorB]: filterList[keyName] ? bodyStyle : value,
        };
      case 'transmission':
        const onRemoveTransmission = onRemoveSpecificItemInArray(
          union([...newValue[1], value]),
          value,
        );
        const transmission =
          onRemoveTransmission.length === 1 ? onRemoveTransmission[0] : onRemoveTransmission;
        if (onRemoveTransmission.length === 0) {
          return [];
        }
        const operatorT = onRemoveTransmission.length === 1 ? 'eq' : 'in';
        return {
          [operatorT]: filterList[keyName] ? transmission : value,
        };
      case 'color':
        const onRemoveColor = onRemoveSpecificItemInArray(union([...newValue[1], value]), value);
        const color = onRemoveColor.length === 1 ? onRemoveColor[0] : onRemoveColor;
        if (onRemoveColor.length === 0) {
          return [];
        }
        const operatorC = onRemoveColor.length === 1 ? 'eq' : 'in';
        return {
          [operatorC]: filterList[keyName] ? color : value,
        };
      case 'ports':
        const onRemovePorts = onRemoveSpecificItemInArray(union([...newValue[1], value]), value);
        const ports = onRemovePorts.length === 1 ? onRemovePorts[0] : onRemovePorts;
        if (onRemovePorts.length === 0) {
          return [];
        }
        const operatorP = onRemovePorts.length === 1 ? 'eq' : 'in';
        return {
          [operatorP]: filterList[keyName] ? ports : value,
        };
      case 'mileage':
        return {
          operator: 'between',
          mileage: onRemoveValueOdometer([...newValue[1], value], value),
        };
      case 'optionals':
        const onRemoveOptionals = onRemoveSpecificItemInArray(
          union([...newValue[1], value]),
          value,
        );
        const optionals = onRemoveOptionals.length === 1 ? onRemoveOptionals[0] : onRemoveOptionals;
        if (onRemoveOptionals.length === 0) {
          return [];
        }
        return {
          include: filterList[keyName] ? [optionals] : [value],
        };
      case 'userType':
        const onRemoveUserType = onRemoveSpecificItemInArray(union([...newValue[1], value]), value);
        const userType = onRemoveUserType.length === 1 ? onRemoveUserType[0] : onRemoveUserType;
        if (onRemoveUserType.length === 0) {
          return [];
        }
        const operatorUser = onRemoveUserType.length === 1 ? 'eq' : 'in';
        return {
          [operatorUser]: filterList[keyName] ? userType : value,
        };
    }
  }
};

export const unCheckedAllCheckBox = (singleClass: string) => {
  const allCheckBox = document.querySelectorAll<HTMLInputElement>(`.${singleClass}`);
  return map(allCheckBox, item => (item.checked = false));
};
