import React from 'react';

export interface ILocation {
  city: string;
  state: string;
}

export const Location = ({ city, state }: ILocation) => {
  return (
    <li className="pt4 ph16">
      <p className="ma0 f11 f12-m f12-l boulder fw3-m fw4-l">
        {city} - {state}
      </p>
    </li>
  );
};
