import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import _uniqueId from 'lodash/uniqueId';
import { Icon } from '../../components/Icons';
import { getIcons, Icons } from '../../utils/functions';
import styles from './style.module.css';

interface Props {
  servicesData: string[];
  howManyToShow: number;
  iconsList?: Icons[];
}

interface CMSData {
  allDirectusTipoDeServico: {
    nodes: Icons[];
  };
}

const RenderServiceList: React.FC<Props> = ({ servicesData, howManyToShow = 99999, iconsList }) => {
  const isToShow = (index: number) => index + 1 <= howManyToShow;

  const renderServices = () => {
    return servicesData.map((service: string, index: number) => {
      if (isToShow(index)) {
        return (
          service && (
            <li className="trueno f12 abbey list mb8 fw3 flex items-center ml8" key={index}>
              <Icon height={15} name={getIcons(service, iconsList)} className="mr12" />
              <span>{service}</span>
            </li>
          )
        );
      }
    });
  };

  const getHowManyToShow = () => {
    return servicesData.length - howManyToShow;
  };

  const renderTotalOfServices = () => {
    return (
      getHowManyToShow() > 0 && (
        <li className="trueno f12 abbey list mb8 b--abbey bb fl fw3" key={_uniqueId()}>
          <b>+{getHowManyToShow()}</b> serviços
        </li>
      )
    );
  };

  const renderComponents = () => {
    return [renderServices(), renderTotalOfServices()];
  };

  return <ul className={`ma0 pa0 relative mb16 ${styles.splitter}`}>{renderComponents()}</ul>;
};

export const ServiceList: React.FC<Props> = ({ ...props }: Props) => {
  const dataCMS = graphql`
    query {
      allDirectusTipoDeServico {
        nodes {
          name
          icon
        }
      }
    }
  `;

  const renderList = (data: CMSData) => {
    const {
      allDirectusTipoDeServico: { nodes },
    } = data;

    return <RenderServiceList iconsList={nodes} {...props} />;
  };

  return <StaticQuery query={dataCMS} render={renderList} />;
};
