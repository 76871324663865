import React, { useState, useEffect } from 'react';
import compact from 'lodash/compact';
import find from 'lodash/find';
import map from 'lodash/map';
import { Select } from '../../../components/Select';
import { dataCategoryFilter } from '../../../services/mocks';
import { GenericSelectList, GenericSelectOption } from '../../../types/generics';
import { SERVICES_NUMBER } from '../../../utils/productType';

interface Props {
  onClick?: () => void;
  typeSearch?: string;
}

export const FilterSelectCategory = ({ onClick, typeSearch = '' }: Props) => {
  const [options, setOptions] = useState<GenericSelectList>();
  const [selected, setSelected] = useState<GenericSelectOption>();

  useEffect(() => {
    const newOptions: any = compact(
      map(dataCategoryFilter, (item: { id: number; label: string }) => {
        if (item.id < SERVICES_NUMBER) {
          const newItem = {
            label: item.label,
            value: item.id,
          };
          return newItem;
        }
      }),
    );
    setOptions(newOptions);
  }, []);

  useEffect(() => {
    if (typeSearch && options) {
      const newSelected = find(options, item => item.value === typeSearch);
      return setSelected(newSelected);
    }
    setSelected(null);
  }, [typeSearch, options]);

  return (
    <div className="w-100 mt32" style={{ maxWidth: 345 }} id="selectCategory">
      <h5 className="f16 fw7 ttu abbey ma0 mb16">Tipo da peça</h5>
      <div className="flex flex-wrap mb16">
        <Select
          className="w-100"
          options={options}
          onChange={onClick}
          title="Tipo da peça"
          selected={selected}
          locationDropdown="selectCategory"
        />
      </div>
    </div>
  );
};
