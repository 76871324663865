import React, { useState } from 'react';
import { Layout } from '../components/Layout';
import { AsideSearchBarAndContent } from '../modules/AsideSearchBarAndContent';

interface Props {
  location: any;
}
const defaultTitle = 'Compra e venda veículos novos, usados e seminovos | Autoscar';
const SearchAttainment = ({ location }: Props) => {
  const [customTitle, setCustomTitle] = useState(defaultTitle);
  return (
    <Layout title={customTitle}>
      <AsideSearchBarAndContent
        location={location}
        setCustomTitle={setCustomTitle}
        defaultTitle={defaultTitle}
      />
    </Layout>
  );
};

export default SearchAttainment;
