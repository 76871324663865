import React from 'react';
import { get } from 'lodash';
import { StoreByCity } from '../../Filter/StoreByCity';
import { ServicesContentSidebarProps } from '../types';
import { DefaultContentSidebar } from './DefaultContentSidebar';
import { Divider } from './Divider';
import { ServicesFilter } from './ServicesFilter';

export const ServicesContentSidebar = ({
  filterList,
  filters,
  handleStoreByCity,
  selectedCategory,
  services,
  handleCheckboxFilters,
  handleFilterClick,
  sidebarOpen,
  handleClickSearch,
  handleChangeSearch,
  searchBrand,
  isLoading,
  initialValuesSearch,
  currentYear,
  handleGetParamsFromUrlAndSearch,
  clearSearchInputValue,
  changeLocality,
  onSetSidebarOpen,
}: ServicesContentSidebarProps) => {
  return (
    <>
      <DefaultContentSidebar
        handleFilterClick={handleFilterClick}
        sidebarOpen={sidebarOpen}
        handleClickSearch={handleClickSearch}
        handleChangeSearch={handleChangeSearch}
        searchBrand={searchBrand}
        isLoading={isLoading}
        initialValuesSearch={initialValuesSearch}
        currentYear={currentYear}
        handleGetParamsFromUrlAndSearch={handleGetParamsFromUrlAndSearch}
        clearSearchInputValue={clearSearchInputValue}
        selectedCategory={selectedCategory}
        onSetSidebarOpen={onSetSidebarOpen}
      />
      <StoreByCity
        getValues={handleStoreByCity}
        hasStore={!!filterList.fantasyName}
        hasCity={!!filterList.city}
        selected={filterList}
        localities={get(filters, 'city', [])}
        changeLocality={changeLocality}
      />
      <Divider />
      <ServicesFilter services={services} handleChange={handleCheckboxFilters} />
    </>
  );
};
