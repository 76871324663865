import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { find } from 'lodash';
import moment from 'moment';
import { Card } from '../../../../components/Card';
import { CardHeader } from '../../../../components/CardHeader';
import { Divider } from '../../../../components/Divider';
import { RatingViewer } from '../../../../components/RatingViewer';
import { ServiceList } from '../../../../components/ServiceList';
import { ShopAddress } from '../../../../components/ShopData/Complete/components/ShopAddress';
import { isOpen } from '../../../../components/ShopData/Complete/components/ShopOpeningHours';
import { StyledModal } from '../../../../components/StyledModal';
import { ellipsizeTextBox } from '../../../../utils/functions';
import { RatingModule } from '../../../RatingModule';
import { ServiceCardTypes } from '../../types';
import styles from './style.module.css';

export const ServiceCard: React.FC<ServiceCardTypes> = ({
  name,
  srcImg,
  description,
  city,
  cepAddress,
  district,
  street,
  services,
  ratingValue,
  ratings,
  linkToStore,
  id,
  businesshours,
  phone,
  additionalAddressData,
}) => {
  useEffect(() => {
    ellipsizeTextBox(String(id));
  }, []);

  const [openRatingModal, setOpenRatingModal] = useState(false);

  const cardHeaderProps = {
    name,
    srcImg,
  };

  const addressProps = {
    city,
    cepAddress,
    district,
    street,
    additionalAddressData: additionalAddressData || '',
  };

  const toggleRatingModal = () => {
    setOpenRatingModal(!openRatingModal);
  };

  const renderClosed = () => {
    return (
      <span className="trueno f12 abbey tc mb12 fw3">
        <b className="red fw6">Fechado</b>
      </span>
    );
  };

  const renderIsOpen = () => {
    const today: number = +moment(new Date()).format('E');

    const openTodayHours = find(businesshours, item => +item.day === today + 1);

    if (!openTodayHours) {
      return renderClosed();
    }

    const { initialTime, endTime } = openTodayHours;

    const openToday = isOpen(initialTime, endTime);

    if (openToday) {
      return (
        <span className="trueno f12 abbey tc mb12 fw3">
          <b className="grass-green fw6">Aberto</b> - Fecha às <b className="fw6">{endTime}</b>
        </span>
      );
    }

    return renderClosed();
  };

  const ratingModuleProps = {
    srcImg,
    label: 'Comentários Sobre:',
    name,
    id,
  };

  return (
    <Card cssStyles={styles.card}>
      <Link to={linkToStore} className="link w-100">
        <CardHeader {...cardHeaderProps} className="flex items-center justify-center" />
      </Link>
      <p
        id={String(id)}
        className={`f11 trueno boulder tc lh-copy fw3 relative ${styles.splitter}`}
      >
        {description}
      </p>
      <div className={styles.divider}>
        <Divider className="mb12" />
      </div>
      <ServiceList servicesData={services} howManyToShow={4} />
      <div className={styles.divider}>
        <Divider className="mb12" />
      </div>
      <div className={styles.ratingViewerContent}>
        <RatingViewer
          ratingValue={ratingValue}
          edit={false}
          reviewers={ratings.length}
          handleReviews={toggleRatingModal}
        />
        <StyledModal
          isOpen={openRatingModal}
          closeModal={toggleRatingModal}
          className={styles.modal}
        >
          <RatingModule {...ratingModuleProps} />
        </StyledModal>
      </div>
      {renderIsOpen()}
      <a
        href={`tel:${phone}`}
        className={`link fw7 ph32-m ph32-l ttu f14 f18-m f18-l mb12 gradient-green-grass green-shadow-hover w-100 br3 bn f16 pv8 tc white ${styles.callToAction}`}
      >
        Ligar Agora
      </a>
      <div className={styles.addressRevealer}>
        <ShopAddress
          address={addressProps}
          showAddressLabel={false}
          styleAddressMobile={{ marginBottom: 0 }}
        />
      </div>
    </Card>
  );
};
