import React from 'react';
import cx from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
import { orderBy } from 'lodash';
import map from 'lodash/map';
import { CheckBoxFilter } from '../../../components/CheckBoxFilter';
import { normalizeOptionals } from './OptionalsFilter/utils/formattOptionals';
import styles from './style.module.css';

interface Props {
  directusData?: any;
  services: any;
  handleChange: any;
}

const RenderServicesFilter = ({ handleChange, directusData, services }: Props) => {
  const DIVIDER = <div className={cx('mt32 bt b--boulder mb32 o-30', styles.divider)} />;

  let newOptionals = normalizeOptionals(directusData, services);
  newOptionals = orderBy(newOptionals, ['title'], ['asc']);

  const renderCheckboxfilter = map(newOptionals, (item, key) => {
    const { optionals } = item;
    return map(optionals, (itemList, keyList) => {
      return (
        <div key={keyList + key}>
          <CheckBoxFilter
            filterOptions={itemList}
            title="Serviços"
            name="services"
            onChange={handleChange}
          />
          {key > 3 && key < 8 && DIVIDER}
        </div>
      );
    });
  });

  return <div>{renderCheckboxfilter}</div>;
};

export const ServicesFilter = ({ ...props }: Props) => {
  const optionalsData = graphql`
    query {
      allDirectusTipoDeServico(filter: { status: { eq: "published" } }) {
        nodes {
          id
          name
          status
        }
      }
    }
  `;

  const renderServicesFilter = (data: any) => {
    const {
      allDirectusTipoDeServico: { nodes },
    } = data;

    return <RenderServicesFilter directusData={nodes} {...props} />;
  };
  return <StaticQuery query={optionalsData} render={renderServicesFilter} />;
};
