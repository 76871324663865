import React from 'react';
import { FilterSelectCategory } from './FilterSelectCategory';

interface Props {
  onClick: any;
  typeSearch: any;
}

export const PartCategoryFilter = ({ onClick, typeSearch }: Props) => {
  return <FilterSelectCategory onClick={onClick} typeSearch={typeSearch} />;
};
